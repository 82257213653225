import { useStore } from 'effector-react'
import { ReactComponent as CrossIcon } from 'icons/cross.svg'
import { $modalState, closeModal, ModalState } from 'models/flow'
import { ReactNode, useMemo } from 'react'
import { createPortal } from 'react-dom'

enum ModalType {
  TransactionResult,
  Waiting,
  ConnectMetamask,
  MetamaskFailure,
  RegistrationConfirmation,
}

interface Props {
  children: ReactNode
}

const portal = document.getElementById('modal')

export default function ModalContainer({ children }: Props) {
  const showModal = useStore($modalState.map((ms) => ms !== ModalState.Closed))

  const modalType = useStore($modalState.map(getModalType))

  const { width, height /* mobileWidth, mobileHeight */ } = useMemo(
    () => getModalSize(modalType),
    [modalType]
  )

  if (!portal || !showModal) {
    return null
  }
  const content = (
    <>
      <div
        className="absolute z-30 cursor-pointer top-5 right-5"
        onClick={() => closeModal()}
      >
        <CrossIcon className="w-5 h-5 fill-current" />
      </div>
      <div className="relative w-full h-full">
        <div className="relative transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          {children}
        </div>
      </div>
    </>
  )

  return createPortal(
    <>
      <div className="fixed z-40 h-auto top-1/2 left-1/2 box-border transform -translate-x-1/2 -translate-y-1/2 w-xl">
        <div
          className="block relative modal bg-darkOrange rounded-[1.3rem] border border-accent border-opacity-60 shadow-base text-primary"
          style={{ width, height }}
        >
          {content}
        </div>
        {/*<div
          className="xxs:block xs:block s:hidden relative modal bg-darkOrange rounded-[1.3rem] border border-accent border-opacity-60 shadow-base text-primary"
          style={{ width: mobileWidth, height: mobileHeight }}
        >
          {content}
        </div>*/}
      </div>
      <div className="backdrop-filter backdrop-blur-lg bg-darkOrange bg-opacity-60 fixed z-[39] w-screen h-screen" />
    </>,
    portal
  )
}

function getModalType(modalState: ModalState): ModalType {
  switch (modalState) {
    case ModalState.RegistrationConfirmation:
      return ModalType.RegistrationConfirmation
    case ModalState.ConnectMetamask:
      return ModalType.ConnectMetamask
    case ModalState.MetamaskFailure:
      return ModalType.MetamaskFailure
    case ModalState.TxWait:
    case ModalState.MetamaskWaiting:
      return ModalType.Waiting
    case ModalState.TxFailure:
    case ModalState.TxSuccess:
    default:
      return ModalType.TransactionResult
  }
}

function getModalSize(modalType: ModalType) {
  switch (modalType) {
    case ModalType.ConnectMetamask:
      return {
        width: '32rem',
        height: '15rem',
        mobileWidth: '22rem',
        mobileHeight: '20rem',
      }
    case ModalType.MetamaskFailure:
      return {
        width: '32rem',
        height: '18rem',
        mobileWidth: '22rem',
        mobileHeight: '20rem',
      }
    case ModalType.Waiting:
      return {
        width: '32rem',
        height: '14.4rem',
        mobileWidth: '22rem',
        mobileHeight: '20rem',
      }
    case ModalType.TransactionResult:
      return {
        width: '32rem',
        height: '20rem',
        mobileWidth: '22rem',
        mobileHeight: '20rem',
      }
    case ModalType.RegistrationConfirmation:
      return {
        width: '32rem',
        height: '27rem',
        mobileWidth: '22rem',
        mobileHeight: '20rem',
      }
    default:
      return {
        width: '32rem',
        height: '13rem',
        mobileWidth: '22rem',
        mobileHeight: '20rem',
      }
  }
}
