import Countdown, { zeroPad, CountdownRendererFn } from 'react-countdown'

interface Props {
  endTime: string | number | Date
  small?: boolean
}

type TPiece = {
  title: string
  value: number
  small?: boolean
}

type TColon = {
  small?: boolean
}

const Piece = ({ title, value, small }: TPiece) => {
  const classNameNumbers = small
    ? 'w-12 text-3xl'
    : 'w-12 xs:w-14 m:w-16 text-3xl xs:text-4xl m:text-5xl'
  const classNameTitle = small ? 'text-xs ml-0' : 'text-xs m:text-base m:ml-2'

  return (
    <div className="flex">
      <div className={`${classNameNumbers} font-secondary`}>
        {zeroPad(value)}
      </div>
      <div className={`${classNameTitle} relative`}>{title}</div>
    </div>
  )
}

const Colon = ({ small }: TColon) => {
  const className = small
    ? 'mx-2 text-3xl'
    : 'mx-2 xs:mx-3 m:ml-8 m:mr-4 l:ml-6 l:mr-4 xl:ml-8 xl:mr-4 text-3xl xs:text-4xl m:text-5xl'
  return <div className={`${className}  opacity-60 font-secondary`}>:</div>
}

const renderer =
  (small?: boolean): CountdownRendererFn =>
  ({ days, hours, minutes, seconds, completed }) => {
    if (days === 0 && hours === 0 && minutes < 1) {
      const className = small ? 'text-3xl' : 'text-3xl xs:text-4xl m:text-5xl'
      return (
        <div className={`${className} text-accent m:mx-10 font-secondary`}>
          {'<'} 1 MINUTE
        </div>
      )
    }
    return (
      <div className="flex text-accent">
        <Piece title="D" value={days} small={small} />
        <Colon small={small} />
        <Piece title="H" value={hours} small={small} />
        <Colon small={small} />
        <Piece title="M" value={minutes} small={small} />
        <Colon small={small} />
        <Piece title="S" value={seconds} small={small} />
      </div>
    )
  }

export default function Coundown({ endTime, small }: Props) {
  return <Countdown zeroPadTime={2} date={endTime} renderer={renderer(small)} />
}
