import { ReactComponent as IconMedium } from 'icons/medium.svg'
import { ReactComponent as IconTwitter } from 'icons/twitter.svg'
import { ReactComponent as IconTelegram } from 'icons/telegram.svg'
import { ReactComponent as IconWeb } from 'icons/web.svg'
import { ReactComponent as IconApplyForIdo } from 'icons/apply-for-ido.svg'
interface Props {
  link: string
  type: string
  className?: string
  text?: string
}

const getIcon = (type: string): React.ReactNode => {
  switch (type) {
    case 'telegram':
      return <IconTelegram width="1em" height="1em" />
    case 'web':
      return <IconWeb width="1em" height="1em" />
    case 'twitter':
      return <IconTwitter width="1em" height="1em" />
    case 'medium':
      return <IconMedium width="1em" height="1em" />
    case 'applyForIdo':
      return <IconApplyForIdo width="1em" height="1em" />
    default:
      return null
  }
}
export default function SocialButton({
  link,
  type,
  className = '',
  text,
}: Props) {
  const icon = getIcon(type)

  return (
    <a
      href={link}
      target="__blank"
      className={`flex cursor-pointer justify-center rounded-xl items-center p-2 bg-accent bg-opacity-10 hover:bg-opacity-60 transition-colors ${className}`}
    >
      {icon}
      {text && <div className="ml-2">{text}</div>}
    </a>
  )
}
