import noop from 'lodash/noop'

interface Props {
  onClick?(): void
}

export default function MaxButton({ onClick = noop }: Props) {
  const cn = {
    font: 'leading-none text-sm',
    colors: 'text-accent bg-accent bg-opacity-15',
  }
  return (
    <div
      onClick={onClick}
      className={`rounded border-box cursor-pointer px-[0.4rem] pb-[0.25rem] pt-[0.44rem] ${cn.font} ${cn.colors}`}
    >
      MAX
    </div>
  )
}
