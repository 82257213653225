import Decimal from 'decimal.js'
import { guard, sample } from 'effector'
import { TxStatus } from 'gqlgen/public'
import { $stakingAllowed, $stakingInfo } from 'models/app'
import { $authOk } from 'models/user'
import {
  $modalState,
  $txHash,
  $txHashClock,
  $txStatus,
  approveStaking,
  approveStakingFx,
  claimStakingReward,
  claimStakingRewardFx,
  closeModal,
  ModalState,
  openConfirmMetamaskModal,
  openConnectMetamaskModal,
  openFailureModal,
  openMetamaskFailureModal,
  openMetamaskWaitingModal,
  openRegistrationConfirmationModal,
  openRegistrationSuccessModal,
  openTxFailModal,
  openTxSuccessModal,
  openTxWaitModal,
  openWaitingModal,
  txStatusFx,
} from '.'

$modalState
  .on(openTxWaitModal, () => ModalState.TxWait)
  .on(openTxSuccessModal, () => ModalState.TxSuccess)
  .on(openTxFailModal, () => ModalState.TxFailure)
  .on(closeModal, () => ModalState.Closed)
  .on(
    openRegistrationConfirmationModal,
    () => ModalState.RegistrationConfirmation
  )
  .on(openConnectMetamaskModal, () => ModalState.ConnectMetamask)
  .on(openConfirmMetamaskModal, () => ModalState.ConfirmMetamask)
  .on(openMetamaskWaitingModal, () => ModalState.MetamaskWaiting)
  .on(openMetamaskFailureModal, () => ModalState.MetamaskFailure)
  .on(openWaitingModal, () => ModalState.Wait)
  .on(openFailureModal, () => ModalState.Failure)
  .on(openRegistrationSuccessModal, () => ModalState.RegistrationSuccess)

// approveStaking можно делать только если юзер авторизован и его allowance == 0
guard({
  clock: approveStaking,
  source: [$authOk, $stakingAllowed],
  target: approveStakingFx,
  filter: ([authOk, stakingAllowed]) => authOk && !stakingAllowed,
})

// claimStakingReward можно делать только если юзер авторизован и его rewardAvailable > 0
guard({
  clock: claimStakingReward,
  source: [$authOk, $stakingInfo],
  target: claimStakingRewardFx,
  filter: ([authOk, { rewardAvailable }]) =>
    authOk && new Decimal(rewardAvailable ?? 0).gt(0),
})

// если появился новый хеш транзакции - выставляем статус pending
sample({
  source: $txHash,
  target: $txStatus,
  fn: (txHash) => (txHash !== '' ? TxStatus.Pending : TxStatus.Empty),
})

// если есть pending транзакция - проверяем её статус
guard({
  clock: $txHashClock,
  source: $txStatus,
  target: txStatusFx,
  filter: (txStatus) => txStatus === TxStatus.Pending,
})
