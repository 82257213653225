import { Link } from '@reach/router'
import { Countdown, Field, ProjectHeader, ProjectProgress } from 'components'
import { formatUTC } from 'utils/dayjs'

export type ProjectCardProps = {
  id?: string
  bg?: string
  logo?: string
  status?: string
  live?: boolean
  title?: string
  description?: string
  idoStartTime?: number
  projectHave?: string
  projectTotal?: string
  currency?: string
}

export default function ProjectCard({
  id,
  bg,
  logo,
  status,
  live = false,
  title,
  description,
  idoStartTime = 0,
  projectHave = '',
  projectTotal = '',
  currency,
}: ProjectCardProps) {
  return (
    <Link to={`/project/${id}`}>
      <div className="cursor-pointer p-4 xs:p-8 s:p-4 m:p-8 bg-black bg-opacity-60 rounded-lg translate-y-0 hover:-translate-y-3 hover:shadow-project-card transition-allounded-lg ">
        <ProjectHeader bg={bg} logo={logo} status={status} live={live} />
        <div className="my-4">
          <div className="text-2xl mt-2">{title}</div>
          <div className="text-sm h-[4rem] leading-[1.3rem] inline-block overflow-ellipsis overflow-hidden">
            {description}
          </div>
        </div>
        <div className="grid grid-cols-1 s:grid-cols-[6.5rem,1fr] gap-4 s:gap-1 mb-8">
          <Field
            label="Total target"
            value={`${projectTotal ? `${projectTotal}  ${currency}` : 'TBA'}`}
          />
          <Field label="Start" value={formatUTC(idoStartTime)} />
        </div>
        <div className="h-[6rem]">
          {Date.now() < idoStartTime && (
            <>
              <div className="text-accent text-sm mb-2">IDO starts in</div>
              <Countdown small={true} endTime={idoStartTime} />
            </>
            // formatNumber
          )}
        </div>
        <div>
          <ProjectProgress
            have={projectHave}
            total={projectTotal}
            currency={currency}
          />
        </div>
      </div>
    </Link>
  )
}
