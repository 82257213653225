import { useStore } from 'effector-react'
import { $modalState, ModalState } from 'models/flow'
import ConfirmMetamaskModal from './ConfirmMetamaskModal'
import ConnectMetamaskModal from './ConnectMetamaskModal'
import FailureModal from './FailureModal'
import MetamaskFailureModal from './MetamaskFailureModal'
import MetamaskWaitingModal from './MetamaskWaitingModal'
import ModalContainer from './ModalContainer'
import RegistrationConfirmationModal from './RegistrationConfirmationModal'
import RegistrationSuccessModal from './RegistrationSuccessModal'
import TxFailureModal from './TxFailureModal'
import TxSuccessModal from './TxSuccessModal'
import TxWaitModal from './TxWaitModal'
import WaitModal from './WaitModal'

export default function Modals() {
  const modalState = useStore($modalState)

  let modal = null
  switch (modalState) {
    case ModalState.ConnectMetamask:
      modal = <ConnectMetamaskModal />
      break
    case ModalState.ConfirmMetamask:
      modal = <ConfirmMetamaskModal />
      break
    case ModalState.MetamaskWaiting:
      modal = <MetamaskWaitingModal />
      break
    case ModalState.MetamaskFailure:
      modal = <MetamaskFailureModal />
      break
    case ModalState.TxWait:
      modal = <TxWaitModal />
      break
    case ModalState.TxSuccess:
      modal = <TxSuccessModal />
      break
    case ModalState.TxFailure:
      modal = <TxFailureModal />
      break
    case ModalState.Failure:
      modal = <FailureModal />
      break
    case ModalState.Wait:
      modal = <WaitModal />
      break
    case ModalState.RegistrationSuccess:
      modal = <RegistrationSuccessModal />
      break
    case ModalState.RegistrationConfirmation:
      modal = <RegistrationConfirmationModal />
  }

  return <ModalContainer>{modal}</ModalContainer>
}
