import { Link } from '@reach/router'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

interface Props {
  to: string
  text: string
  desktop?: boolean
  navActiveColor?: string
  onClick?: () => void
}
export default function NavLink({
  to,
  text,
  desktop = false,
  onClick = noop,
  navActiveColor = '',
}: Props) {
  const getProps = ({ isCurrent }: { isCurrent: boolean }) => ({
    className: `${desktop ? 'hidden m:block ' : ''} ${
      isCurrent
        ? `${!isEmpty(navActiveColor) ? navActiveColor : 'text-accent'}`
        : 'text-primary'
    } inline-block`,
  })

  return (
    <Link to={to} onClick={onClick} getProps={getProps}>
      {text}
    </Link>
  )
}
