import 'models/app/init'
import 'models/flow/init'
import { loadUserFx, setNetworkIsOk, setUserAddress } from 'models/user'
import 'models/user/init'
import { chainIds } from 'utils/consts'

loadUserFx()

window.ethereum?.on('accountsChanged', (accounts) => {
  if (Array.isArray(accounts) && accounts.length > 0) {
    setUserAddress(accounts[0])
  }
})

window.ethereum?.on('chainChanged', (chainId) => {
  setNetworkIsOk(chainId === chainIds.Arbitrum.ArbitrumMainnet)
})
