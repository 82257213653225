import {
  createEffect,
  createEvent,
  createStore,
  fromObservable,
  restore,
} from 'effector'
import { TxStatus } from 'gqlgen/public'
import { $idoCurrentPoolAddress, $stakingInfo } from 'models/app'
import { $user } from 'models/user'
import { interval } from 'rxjs'
import { convertTxToSendTxRequest } from 'utils'
import { graphqlSdk } from 'utils/consts'
import { toDecimal } from 'utils/numbers'

export enum ModalState {
  TxWait,
  TxSuccess,
  TxFailure,
  Closed,
  ConnectMetamask,
  ConfirmMetamask,
  MetamaskWaiting,
  MetamaskFailure,
  Failure,
  Wait,
  RegistrationSuccess,
  RegistrationConfirmation,
}

export const approveStaking = createEvent()
export const claimStakingReward = createEvent()

export const setTxHash = createEvent<string>()
export const setTxStatus = createEvent<TxStatus>()
export const openTxWaitModal = createEvent()
export const openTxSuccessModal = createEvent()
export const openTxFailModal = createEvent()
export const openRegistrationConfirmationModal = createEvent()
export const openConnectMetamaskModal = createEvent()
export const openConfirmMetamaskModal = createEvent()
export const openMetamaskWaitingModal = createEvent()
export const openMetamaskFailureModal = createEvent()
export const openWaitingModal = createEvent()
export const openFailureModal = createEvent()
export const openRegistrationSuccessModal = createEvent()
export const closeModal = createEvent()

export const $modalState = createStore<ModalState>(ModalState.Closed)
export const $txHash = restore(setTxHash, '')
export const $txHashClock = fromObservable(interval(2000))
export const $txStatus = restore(setTxStatus, TxStatus.Empty)

export const approveStakingFx = createEffect(async () => {
  const { session } = $user.getState()

  try {
    openWaitingModal()
    const input = { amount: '100000000000000000000000000' } // todo: blockchain/token specific
    const { createStakingApproveTx: tx } =
      await graphqlSdk.CreateStakingApproveTx({ session, input })
    const hash: string = await window.ethereum?.request(
      convertTxToSendTxRequest(tx)
    )
    setTxHash(hash)
    openTxWaitModal()
  } catch (err) {
    closeModal()
  }
})

export const claimStakingRewardFx = createEffect(async () => {
  const { session, address } = $user.getState()

  try {
    openWaitingModal()
    const input = { address } // todo: should be no address here?
    const { createStakingClaimRewardTx: tx } =
      await graphqlSdk.CreateStakingClaimRewardTx({ session, input })
    const hash: string = await window.ethereum?.request(
      convertTxToSendTxRequest(tx)
    )
    setTxHash(hash)
    openTxWaitModal()
  } catch (err) {
    closeModal()
  }
})

export const stakeFx = createEffect(async (amount: string) => {
  const { session, address } = $user.getState()

  try {
    openWaitingModal()
    const input = { amount, address } // todo: should be no address here?
    const { createStakingStakeTx: tx } = await graphqlSdk.CreateStakingStakeTx({
      session,
      input,
    })
    const hash: string = await window.ethereum?.request(
      convertTxToSendTxRequest(tx)
    )
    setTxHash(hash)
    openTxWaitModal()
  } catch (err) {
    closeModal()
  }
})

export const unstakeFx = createEffect(async (amount: string) => {
  const { session, address } = $user.getState()
  const { unstakingFeeRatio, stakingToken } = $stakingInfo.getState()

  try {
    openWaitingModal()
    const input = {
      amount,
      address,
      maximumFee: toDecimal(amount)
        .mul(stakingToken.decimals)
        .mul(toDecimal(unstakingFeeRatio))
        .toFixed(),
    } // todo: should be no address here?
    const { createStakingUnstakeTx: tx } =
      await graphqlSdk.CreateStakingUnstakeTx({
        session,
        input,
      })
    const hash: string = await window.ethereum?.request(
      convertTxToSendTxRequest(tx)
    )
    setTxHash(hash)
    openTxWaitModal()
  } catch (err) {
    closeModal()
  }
})

export const buyFx = createEffect(async (amount: string) => {
  const { session } = $user.getState()
  const idoCurrentPoolAddress = $idoCurrentPoolAddress.getState()
  openTxWaitModal()
  try {
    const { createPoolReserveTx: tx } = await graphqlSdk.CreatePoolReserveTx({
      input: { session: session, poolAddress: idoCurrentPoolAddress, amount },
    })
    const hash = await window.ethereum?.request(convertTxToSendTxRequest(tx))
    setTxHash(hash)
    openTxWaitModal()
  } catch (err) {
    openFailureModal()
    console.error(err)
  }
})

export const claimFx = createEffect(async () => {
  const { session } = $user.getState()
  const idoCurrentPoolAddress = $idoCurrentPoolAddress.getState()

  openTxWaitModal()
  try {
    const { createPoolClaimTx: tx } = await graphqlSdk.CreatePoolClaimTx({
      input: { session: session, poolAddress: idoCurrentPoolAddress },
    })
    const hash = await window.ethereum?.request(convertTxToSendTxRequest(tx))
    setTxHash(hash)
    openTxWaitModal()
  } catch (err) {
    openFailureModal()
    console.error(err)
  }
})

export const refundFx = createEffect(async () => {
  const { session } = $user.getState()
  const idoCurrentPoolAddress = $idoCurrentPoolAddress.getState()

  openTxWaitModal()
  try {
    const { createPoolRefundTx: tx } = await graphqlSdk.CreatePoolRefundTx({
      input: { session: session, poolAddress: idoCurrentPoolAddress },
    })
    const hash = await window.ethereum?.request(convertTxToSendTxRequest(tx))
    setTxHash(hash)
    openTxWaitModal()
  } catch (err) {
    openFailureModal()
    console.error(err)
  }
})

export const registrationFx = createEffect(async () => {
  const { session } = $user.getState()
  const idoCurrentPoolAddress = $idoCurrentPoolAddress.getState()

  openWaitingModal()
  try {
    const {
      registerPoolParticipation: { success },
    } = await graphqlSdk.RegisterPoolParticipation({
      input: {
        poolFakeAddress: idoCurrentPoolAddress,
        session: session,
      },
    })

    if (success) {
      openRegistrationSuccessModal()
    } else {
      openFailureModal()
    }
  } catch (err) {
    openFailureModal()
    console.error(err)
  }
})

export const txStatusFx = createEffect(async () => {
  const { session } = $user.getState()
  const txHash = $txHash.getState()
  try {
    const { getTransaction: tx } = await graphqlSdk.Transaction({
      session,
      txHash,
    })
    setTxStatus(tx.status)

    switch (tx.status) {
      case TxStatus.Failed:
        openTxFailModal()
        break
      case TxStatus.Success:
        openTxSuccessModal()
        break
    }
  } catch {
    // openFailureModal()
  }
})

closeModal.watch(() => {
  setTxHash('')
})
