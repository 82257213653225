import Decimal from 'decimal.js'

type NumberToFormat = string | number | Decimal | undefined

interface Options {
  fractionDigits?: number
  detailed?: boolean
  useGrouping?: boolean
}

function getFractionDigits(n: NumberToFormat, options?: Options) {
  let LESS_THAN_1 = 8
  let MORE_OR_EQUAL_THAN_1 = 2

  if (options?.detailed) {
    MORE_OR_EQUAL_THAN_1 = 4
  } else if (options?.fractionDigits) {
    LESS_THAN_1 = options.fractionDigits
    MORE_OR_EQUAL_THAN_1 = options.fractionDigits
  }

  if (typeof n === 'undefined') {
    return 0
  }

  function getDefault(num: number) {
    return num < 1 ? LESS_THAN_1 : MORE_OR_EQUAL_THAN_1
  }

  if (typeof n === 'string') {
    let con = parseFloat(n)
    if (isNaN(con)) {
      return 0
    }
    return getDefault(con)
  }

  if (n instanceof Decimal) {
    return n.lessThan(1) ? LESS_THAN_1 : MORE_OR_EQUAL_THAN_1
  }

  return getDefault(n)
}

function formatNumber(n: NumberToFormat, options?: Options) {
  const fractionDigits = getFractionDigits(n, options)
  const useGrouping = options?.useGrouping ?? true

  if (typeof n === 'undefined') {
    return 'N/A'
  }

  const formatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: fractionDigits,
    useGrouping,
  })

  const downed = (dec: Decimal) =>
    dec.toDecimalPlaces(fractionDigits, Decimal.ROUND_DOWN).toNumber()

  if (typeof n === 'string') {
    let con = parseFloat(n)
    if (isNaN(con)) {
      return JSON.stringify(n)
    }

    return formatter.format(downed(new Decimal(con)))
  }

  if (n instanceof Decimal) {
    return formatter.format(downed(n))
  }

  return formatter.format(downed(new Decimal(n)))
}

function toDecimal(n: any) {
  try {
    return new Decimal(n ?? '0')
  } catch (error) {
    return new Decimal(0)
  }
}

export { formatNumber, toDecimal }
