import { Redirect, Router } from '@reach/router'
import { Main } from 'components'
import 'models/init'
import {
  Home,
  LMRoute,
  ProjectDetailsRoute,
  StakingRoute,
  Dashboard,
} from 'routes'

function App() {
  return (
    <Router primary={false}>
      <Main path="/">
        {/* <Home path="/" /> */}
        <ProjectDetailsRoute path="ido" />
        <ProjectDetailsRoute path="project/:id" />
        <StakingRoute path="/" />
        <StakingRoute path="staking" />
        <LMRoute path="liquidity-mining" />
        <Dashboard path="dashboard" />
        <Redirect from="*" to="/" noThrow />
      </Main>
    </Router>
  )
}

export default App
