import { ReactNode } from 'react'

interface Props {
  label: string
  value: string | number | ReactNode
  className?: string
}

export default function Field({ label, value, className = '' }: Props) {
  return (
    <div className={`text-primary ${className}`}>
      <div className="text-sm leading-4">{label}</div>
      <div className="text-base l:text-[0.95rem] xl:text-base font-bold leading-5">
        {value}
      </div>
    </div>
  )
}
