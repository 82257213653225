import { ReactComponent as IconLink } from 'icons/link-icon.svg'

interface Props {
  url: string
  title: string
  className?: string
}

export default function BlankLink({ url, title, className }: Props) {
  return (
    <a
      href={url}
      target="__blank"
      className={`inline-block text-sm text-accent ${className}`}
    >
      <span className="align-middle">{title}</span>
      <IconLink
        width="1em"
        height="1em"
        className="inline-block align-middle relative top-[-2px] ml-2 fill-current text-accent"
      />
    </a>
  )
}
