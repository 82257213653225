import noop from 'lodash/noop'
import React, { useEffect, useState } from 'react'
import { Popover, PopoverPosition } from 'react-tiny-popover'
import { ReactComponent as InfoIcon } from 'icons/info-icon.svg'

type Positions = Exclude<PopoverPosition, 'custom'>[]

interface InfoPopoverProps {
  text: React.ReactNode | string
  iconId?: string
  className?: string
  children?: JSX.Element | null
  positions?: Positions
  open?: boolean
  showOnClick?: boolean
  onIconClick?(): void
}

const defaultPositions = ['right', 'top', 'left', 'bottom'] as Positions

export default function InfoPopover(props: InfoPopoverProps) {
  const {
    text,
    className,
    children = null,
    positions = defaultPositions,
    open = false,
    showOnClick = false,
    onIconClick = noop,
  } = props

  const [isPopoverOpen, setPopoverOpen] = useState(false)
  const [triggerHover, setTriggerHover] = useState(false)
  const [isContentHover, setIsContentHover] = useState(false)

  useEffect(() => {
    if (showOnClick) {
      return
    }
    if (triggerHover) {
      setPopoverOpen(true)
      return
    }

    if (!triggerHover && !isContentHover) {
      setPopoverOpen(false)
    }
  }, [isContentHover, showOnClick, triggerHover])

  const handleMouseEnter = () => {
    if (showOnClick) {
      return
    }
    setTriggerHover(true)
  }

  const handleIconClick = () => {
    if (showOnClick) {
      setPopoverOpen(true)
      onIconClick()
    }
  }

  const ch = children
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: () => setTriggerHover(false),
        })
      )
    : null
  const handleContentClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <Popover
      isOpen={isPopoverOpen || open}
      padding={8}
      positions={positions}
      content={() => (
        /*{
          position
        }*/
        <div
          className={`relative z-50 bg-black text-primary  p-4 text-base rounded-lg ${className}`}
          onClick={handleContentClick}
          onMouseEnter={() => setIsContentHover(true)}
          onMouseLeave={() => setIsContentHover(false)}
        >
          {text}
        </div>
      )}
    >
      {ch ? (
        ch[0]
      ) : (
        <div
          className="inline-block relative z-50"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setTriggerHover(false)}
          onClick={handleIconClick}
        >
          <InfoIcon width="1.2rem" height="1.2rem" />
        </div>
      )}
    </Popover>
  )
}
