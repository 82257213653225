import {
  Button,
  CurrencyInputPanel,
  SocialButton,
  Tabs,
  YourAllocationProgress,
} from 'components'
import Decimal from 'decimal.js'
import { useStore } from 'effector-react'
import { GeneralInfoQuery } from 'gqlgen/public'
import { ReactComponent as IconAdd } from 'icons/add.svg'
import { ReactComponent as IconArrowBottom } from 'icons/arrow-bottom.svg'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import {
  buyFx,
  claimFx,
  openConnectMetamaskModal,
  openRegistrationConfirmationModal,
  refundFx,
} from 'models/flow'
import {
  $authOk,
  $networkIsOk,
  $user,
  addL2PADTokenFx,
  switchNetworkFx,
} from 'models/user'
import { useState } from 'react'
import { t } from 'utils'
import { formatUTC, toTimestamp } from 'utils/dayjs'
import { formatNumber, toDecimal } from 'utils/numbers'

interface Props {
  pool: GeneralInfoQuery['pools'][number]
}

export default function ParticipateForm({ pool }: Props) {
  const [value, setValue] = useState('')

  const authOk = useStore($authOk)
  const networkIsOk = useStore($networkIsOk)
  const user = useStore($user)

  const handleTypeInput = (v: string) => {
    if (v === '0' || v.match(/^0\./)) {
      setValue(v)
    } else if (v === '00' || v.match(/^00\./)) {
      setValue(v.replace(/^00/, '0'))
    } else {
      setValue(v.replace(/^0+/, ''))
    }
  }

  const yourMaxAllocation = toDecimal(
    find(pool?.tiers, {
      level: pool?.account?.tier,
    })?.maxAllocation
  )

  const availableAllocation = new Decimal(yourMaxAllocation ?? 0).minus(
    new Decimal(pool.account?.amountPaid ?? 0)
  )

  const userToReserve = toDecimal(pool.account?.userToReserve)

  const yourMaxAllocationInIdoTokens = Decimal.mul(
    yourMaxAllocation ?? 0,
    pool?.priceReverse ?? 0
  )

  const youReceiveValue = toDecimal(value).mul(toDecimal(pool.priceReverse))

  const boughtAmount = toDecimal(pool.account?.userToReserve).plus(
    toDecimal(pool.account?.claimAmount)
  )

  // todo: check logic
  // let totalClaimed = `${pool.account?.claimAmount} / ${boughtAmount}`

  // if (new Decimal(pool.account?.userToReserve ?? 0).greaterThan(0)) {
  //   totalClaimed = `0 / ${pool.account?.userToReserve}`
  // }
  // if (
  //   new Decimal(pool.account?.userToReserve ?? 0).equals(0) &&
  //   new Decimal(pool.account?.claimAmount ?? 0).greaterThan(0)
  // ) {
  //   totalClaimed = `${pool.account?.claimAmount} / ${pool.account?.claimAmount}`
  // }

  // todo use idoFinished for render form (not tabs)
  const idoFinished =
    pool.poolStatus === 'SoldOut' || pool.poolStatus === 'SuccessfullyFinished'

  const idoFailed = pool.poolStatus === 'Failed'

  const setMaxAvailableAllocation = () => {
    setValue(availableAllocation.toString())
  }

  const valueDecimal = toDecimal(value)
  // todo: check network
  const buyButtonDisabled =
    pool.poolStatus !== 'InProgress' ||
    !pool.account?.isWhitelisted ||
    !authOk ||
    !networkIsOk ||
    valueDecimal.greaterThan(yourMaxAllocation) ||
    valueDecimal.lessThan(toDecimal(pool.minOrderSizeTargetToken)) ||
    valueDecimal.isNaN()

  // todo
  const registrationButtonDisabled = isEmpty(user.session)

  const isClaimeAvailable =
    toTimestamp(pool.currentTime) - 15 >= toTimestamp(pool.pipeline.claimTime)

  const claimButtonDisabled =
    !authOk ||
    !networkIsOk ||
    toDecimal(pool.account?.userToReserve).eq(0) ||
    !isClaimeAvailable

  const handleSubmitBuy = (amount: string) => {
    buyFx(amount).then(() => {
      setValue('')
    })
  }

  const handleSumbitClaim = () => {
    claimFx()
  }

  const handleSumbitRefund = () => {
    refundFx()
  }

  const handleAddL2PADToken = () => {
    addL2PADTokenFx()
  }

  const handleConnectClick = () => {
    if (authOk) {
      switchNetworkFx()
    } else {
      openConnectMetamaskModal()
    }
  }

  const idoToken = pool.idoToken
  const pipeline = pool.pipeline

  const socialButtons = [
    // { type: 'web', link: pool.meta?.website },
    { type: 'medium', link: pool.meta?.medium },
    { type: 'twitter', link: pool.meta?.twitter },
    { type: 'telegram', link: pool.meta?.telegram },
  ]

  const tabNames = idoFinished
    ? [{ name: 'Buy' }, { name: 'Claim' }]
    : [
        { name: 'Buy' },
        {
          name: 'Claim',
          popoverText:
            'You will be able to claim your tokens after the end of the IDO',
        },
      ]

  return (
    <div className="p-6 s:p-10 relative bg-black bg-opacity-35 border border-accent rounded-xl border-opacity-60 shadow-base">
      {(!authOk || !networkIsOk) && (
        <div className="absolute w-full h-full backdrop-filter backdrop-blur-lg left-0 top-0 z-30 rounded-xl">
          <div
            onClick={handleConnectClick}
            className="h-full flex justify-center items-center text-accent text-xl hover:underline no-underline cursor-pointer"
          >
            {!authOk ? t('connect_metamask') : t('switch_to_arbitrum')}
          </div>
        </div>
      )}
      <div className="mb-4 sm:mb-10 sm:flex items-center justify-between">
        <div className="text-2xl font-bold mb-6 sm:mb-0">Participate</div>
        <div className="flex justify-center sm:justify-end">
          <Tabs
            current={{ name: idoFinished ? 'Claim' : 'Buy' }}
            tabs={tabNames}
          />
        </div>
      </div>
      {/* todo idoFinished */}
      {!idoFinished && !idoFailed && (
        <>
          <CurrencyInputPanel
            availableLabel="Available allocation"
            available={availableAllocation}
            onMax={setMaxAvailableAllocation}
            label="You send"
            symbol={pool.targetToken?.symbol!}
            disabled={
              !pool.account?.isWhitelisted || pool.poolStatus !== 'InProgress'
            }
            value={value}
            onUserInput={handleTypeInput}
          />
          <div className="relative">
            <div className="absolute px-3 py-2 border rounded-lg left-1/2 -translate-x-1/2 top-2 -translate-y-1/2 bg-arrowBgGray border-borderGray">
              <IconArrowBottom
                width=".6em"
                height="1em"
                className="fill-current text-borderGray"
              />
            </div>
          </div>
          <div className="mt-4">
            <CurrencyInputPanel
              label="You receive"
              symbol={idoToken.symbol ?? ''}
              value={formatNumber(youReceiveValue, { fractionDigits: 12 })}
              disabled={true}
              dark={true}
            />
          </div>
          {pool.account &&
          !pool.account?.isRegistered &&
          pool.tiers?.length! > 1 &&
          pool.poolStatus === 'Registration' ? (
            <Button
              className="w-full my-10 text-lg uppercase"
              shadow
              disabled={registrationButtonDisabled}
              onClick={() => openRegistrationConfirmationModal()}
              useMetamask={true}
            >
              {t('Registration')}
            </Button>
          ) : (
            <Button
              className="w-full my-10 text-lg uppercase"
              shadow
              disabled={buyButtonDisabled}
              onClick={() => handleSubmitBuy(value)}
              useMetamask={true}
            >
              {valueDecimal.isNaN() ||
              valueDecimal.greaterThan(yourMaxAllocation) ||
              (valueDecimal.lessThan(toDecimal(pool.minOrderSizeTargetToken)) &&
                !valueDecimal.eq(new Decimal(0)))
                ? 'Enter a valid amount'
                : pool.poolStatus === 'InProgress' &&
                  (isNull(pool.account?.tier) || pool.account?.tier === 0)
                ? 'Not eligible'
                : 'Buy'}
            </Button>
          )}
        </>
      )}
      {idoFinished && (
        <>
          <CurrencyInputPanel
            label="Amount bought"
            symbol={idoToken?.symbol ?? ''}
            value={boughtAmount.toString()}
            disabled={true}
            dark={true}
          />
          {pipeline.claimTime !== '2100-01-01T00:00:00Z' ? (
            <>
              <div className="mt-8">
                <div className="flex justify-between mb-2">
                  <div>Claim Date</div>
                  <div className="text-accent">
                    {formatUTC(pipeline?.claimTime ?? '')}
                  </div>
                </div>
                {/* <div className="flex justify-between">
              <div>Total claimed</div>
              <div className="text-accent">{totalClaimed}</div>
            </div> */}
              </div>
              <Button
                className="w-full my-10 text-lg uppercase"
                shadow
                disabled={claimButtonDisabled}
                onClick={() => handleSumbitClaim()}
                useMetamask={true}
              >
                Claim
              </Button>
            </>
          ) : (
            <div className="text-center mt-4 text-accent text-base">
              <div className="px-4 mb-6">
                You don’t need to send a transaction to claim L2PAD tokens. They
                will be sent to your wallet.
              </div>
              <Button
                className="mb-6 py-4"
                onClick={() => handleAddL2PADToken()}
              >
                <div className="flex items-center">
                  <div className="flex leading-none text-accent">
                    <div className="mr-2">Add L2PAD to MetaMask</div>
                    <IconAdd width="1em" height="1em" />
                  </div>
                </div>
              </Button>
              <div className="mb-2">Follow our social media updates.</div>
              <div className="flex h-8 justify-center">
                {socialButtons.map((btn) => {
                  return btn.link ? (
                    <SocialButton
                      key={`social-btn-${btn.type}`}
                      className="mr-4 hover:bg-opacity-30"
                      type={btn.type}
                      link={btn.link}
                    />
                  ) : null
                })}
              </div>
            </div>
          )}
        </>
      )}
      {idoFailed && (
        <>
          <CurrencyInputPanel
            label="Refund amount"
            symbol={pool.targetToken?.symbol!}
            value={get(pool, 'account.refundAmount', '0').toString()}
            disabled={true}
            dark={true}
          />
          <div className="mt-8">
            <div className="flex justify-between mb-2">
              <div>Refund Date</div>
              <div className="text-accent">
                {formatUTC(pipeline?.finishTime ?? '')}
              </div>
            </div>
          </div>
          <Button
            className="w-full my-10 text-lg uppercase"
            shadow
            disabled={claimButtonDisabled}
            onClick={() => handleSumbitRefund()}
            useMetamask={true}
          >
            Refund
          </Button>
        </>
      )}
      <div className="mb-8">
        {!idoFinished && !idoFailed && (
          <YourAllocationProgress
            title="Used allocation"
            youReceive={youReceiveValue.toString()}
            have={userToReserve.toString()}
            total={yourMaxAllocationInIdoTokens.toString()}
            currency={idoToken?.symbol ?? ''}
          />
        )}
        {idoFinished && pipeline.claimTime !== '2100-01-01T00:00:00Z' && (
          <YourAllocationProgress
            title="Tokens claimed"
            youReceive="0"
            have={pool.account?.claimAmount!}
            total={boughtAmount.toString()}
            currency={idoToken?.symbol ?? ''}
          />
        )}
      </div>
    </div>
  )
}
