import Decimal from 'decimal.js'
import { formatNumber, toDecimal } from 'utils/numbers'
interface Props {
  youReceive: string
  have: string
  total: string
  currency?: string
  title: string
}

export default function YourAllocationProgress({
  youReceive,
  have,
  total,
  currency,
  title,
}: Props) {
  const percent = toDecimal(have).mul(new Decimal(100)).div(toDecimal(total))

  const percentWithYouSend = toDecimal(have)
    .plus(toDecimal(youReceive))
    .mul(new Decimal(100))
    .div(toDecimal(total))

  return (
    <div>
      <div className="flex justify-center s:justify-between relative">
        <div className="flex">
          <div className="mr-2">{title}</div>
          {/* {

          } */}
          <div className="text-accent">
            {toDecimal(total).eq(new Decimal(0))
              ? '0%'
              : `${formatNumber(percent, { fractionDigits: 2 })}%`}
          </div>
        </div>
        <div className="text-accent font-bold s:static s:translate-x-0 absolute left-1/2 -translate-x-1/2 top-8">
          {formatNumber(have)}/{formatNumber(total)} {currency}
        </div>
      </div>
      <div className="flex w-full">
        <div className="relative w-full">
          <div
            className="absolute z-20 h-[2px] bg-accent shadow-allocation-progress"
            style={{ width: `${percent}%` }}
          ></div>
          <div
            className="absolute z-10 h-[2px] bg-accent bg-opacity-35 shadow-allocation-progress"
            style={{
              width: `${
                percentWithYouSend.greaterThan(new Decimal(100))
                  ? '100'
                  : percentWithYouSend
              }%`,
            }}
          ></div>
          <div className="absolute z-0 top-0 bg-accent bg-opacity-10 w-full h-[2px]"></div>
        </div>
      </div>
    </div>
  )
}
