import { formatNumber } from 'utils/numbers'
import Decimal from 'decimal.js'

interface Props {
  have: string
  total: string
  symbol?: string
}

export default function Progress({ have, total, symbol }: Props) {
  const percent = new Decimal(Number(have) ?? 0)
    .mul(new Decimal(100))
    .div(new Decimal(Number(total) ?? 0))

  const percentMoreThenTotal = percent.greaterThan(new Decimal(100))

  return (
    <div>
      <div className="flex mb-4 justify-between text-2xl">
        <div className="flex">
          <div className="mr-4">Progress</div>
          {percent.gt(0) && (
            <div className="text-accent">
              {formatNumber(percent, { fractionDigits: 2 })}%
            </div>
          )}
        </div>
        {have && total ? (
          <div>
            {formatNumber(have)} / {formatNumber(total)} {symbol}
          </div>
        ) : (
          'TBA'
        )}
      </div>
      <div className="flex w-full">
        <div className="relative z-0 w-full">
          <div
            className={`relative z-10 h-[9px] bg-project-progress shadow-allocation-progress overflow-hidden 
            ${
              have === total || percentMoreThenTotal
                ? 'rounded-lg'
                : 'rounded-l-lg'
            }`}
            style={{
              width: `${
                !percent.eq(0) && total
                  ? percentMoreThenTotal
                    ? '100'
                    : percent
                  : '0.5'
              }%`,
            }}
          >
            <div
              className={`bg-white h-[5px] w-full absolute top-1/2 translate-y-[-56%] left-1 
              ${
                have === total || percentMoreThenTotal
                  ? 'rounded-lg w-[98.4%] s:w-[99%] m:w-[99.4%]'
                  : 'rounded-l-lg w-full'
              }`}
            ></div>
          </div>
          <div className="absolute z-0 top-0 bg-black bg-opacity-35 w-full h-[9px] rounded-lg"></div>
        </div>
      </div>
    </div>
  )
}
