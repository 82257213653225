import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Address: string
  Decimal: string
  Hash: string
  Session: string
  Time: string
}

export enum AccessType {
  Private = 'Private',
  Public = 'Public',
}

export type AuthInput = {
  authMessage: Scalars['String']
  sign: Scalars['String']
}

export type AuthResult = {
  __typename?: 'AuthResult'
  address: Scalars['Address']
  session: Scalars['Session']
}

export type Balance = {
  __typename?: 'Balance'
  amount: Scalars['Decimal']
  token: Token
}

export enum Blockchain {
  Arbitrum = 'Arbitrum',
  Bsc = 'BSC',
  Ethereum = 'Ethereum',
}

export enum BlockchainNetwork {
  ArbitrumMainnet = 'ArbitrumMainnet',
  ArbitrumTestnet = 'ArbitrumTestnet',
  BscMainnet = 'BSCMainnet',
  BscTestnet = 'BSCTestnet',
  EthereumMainnet = 'EthereumMainnet',
  EthereumRinkebyTestnet = 'EthereumRinkebyTestnet',
}

export type CreatePoolClaimTxInput = {
  poolAddress: Scalars['Address']
  session: Scalars['Session']
}

export type CreatePoolRefundTxInput = {
  poolAddress: Scalars['Address']
  session: Scalars['Session']
}

export type CreatePoolReserveTxInput = {
  amount: Scalars['Decimal']
  poolAddress: Scalars['Address']
  session: Scalars['Session']
}

export type CreateStakingApproveTxInput = {
  amount: Scalars['Decimal']
}

export type CreateStakingClaimRewardTxInput = {
  address: Scalars['Address']
}

export type CreateStakingStakeTxInput = {
  address: Scalars['Address']
  amount: Scalars['Decimal']
}

export type CreateStakingUnstakeTxInput = {
  address: Scalars['Address']
  amount: Scalars['Decimal']
  maximumFee: Scalars['Decimal']
}

export type DashboardInfo = {
  __typename?: 'DashboardInfo'
  CirculatingSupplyOfRT: Balance
  PTInitialPrice: Balance
  PTPerDayInCurrentEpoch: Balance
  RTConditionedYield: Balance
  RequiredTTStakedForCalculation: Balance
  TTStaked: Balance
  TotalSupplyOfRT: Balance
}

export type EthereumTx = Tx & {
  __typename?: 'EthereumTx'
  blockchain: Blockchain
  chainId: Scalars['String']
  data: Scalars['String']
  from: Scalars['Address']
  gas: Scalars['String']
  gasFeeCap: Scalars['String']
  gasPrice: Scalars['String']
  gasTipCap: Scalars['String']
  nonce: Scalars['String']
  to: Scalars['Address']
  value: Scalars['String']
}

export type FixedPricePool = Pool & {
  __typename?: 'FixedPricePool'
  accessType: AccessType
  account?: Maybe<PoolAccount>
  address: Scalars['Address']
  blockchain: Blockchain
  currentTime: Scalars['Time']
  id: Scalars['ID']
  idoToken: Token
  meta: PoolMeta
  minOrderSizeTargetToken: Scalars['Decimal']
  network: BlockchainNetwork
  pipeline: PoolPipeline
  poolStatus: PoolStatus
  poolType: PoolType
  price?: Maybe<Scalars['Decimal']>
  priceReverse?: Maybe<Scalars['Decimal']>
  progress: PoolProgress
  stakingAddress: Scalars['Address']
  statistics: PoolStatistics
  targetToken?: Maybe<Token>
  tiers: Array<Tier>
  verifiedStatus: VerifiedStatus
  waves?: Maybe<PoolWaves>
  whitelistAddress: Scalars['Address']
}

export type KycInfo = {
  __typename?: 'KycInfo'
  generalLink: Scalars['String']
  lastSyncTime?: Maybe<Scalars['Time']>
  status: KycStatus
  supportLink: Scalars['String']
  usInvestorsLink: Scalars['String']
}

export enum KycStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export type LogInput = {
  error: Scalars['String']
  meta: Scalars['String']
}

export type Member = {
  __typename?: 'Member'
  avatar: Scalars['String']
  linkedIn: Scalars['String']
  name: Scalars['String']
  position: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  auth: AuthResult
  createPoolClaimTx: Tx
  createPoolRefundTx: Tx
  createPoolReserveTx: Tx
  createStakingApproveTx: Tx
  createStakingClaimRewardTx: Tx
  createStakingStakeTx: Tx
  createStakingUnstakeTx: Tx
  getAuthMessage: Scalars['String']
  registerPoolParticipation: PoolParticipationResponse
  writeLog: Scalars['Boolean']
}

export type MutationAuthArgs = {
  input: AuthInput
}

export type MutationCreatePoolClaimTxArgs = {
  input: CreatePoolClaimTxInput
}

export type MutationCreatePoolRefundTxArgs = {
  input: CreatePoolRefundTxInput
}

export type MutationCreatePoolReserveTxArgs = {
  input: CreatePoolReserveTxInput
}

export type MutationCreateStakingApproveTxArgs = {
  input: CreateStakingApproveTxInput
  session: Scalars['Session']
}

export type MutationCreateStakingClaimRewardTxArgs = {
  input: CreateStakingClaimRewardTxInput
  session: Scalars['Session']
}

export type MutationCreateStakingStakeTxArgs = {
  input: CreateStakingStakeTxInput
  session: Scalars['Session']
}

export type MutationCreateStakingUnstakeTxArgs = {
  input: CreateStakingUnstakeTxInput
  session: Scalars['Session']
}

export type MutationGetAuthMessageArgs = {
  address: Scalars['Address']
}

export type MutationRegisterPoolParticipationArgs = {
  input: RegisterPoolParticipationInput
}

export type MutationWriteLogArgs = {
  input: LogInput
}

export type Pool = {
  accessType: AccessType
  account?: Maybe<PoolAccount>
  address: Scalars['Address']
  blockchain: Blockchain
  currentTime: Scalars['Time']
  id: Scalars['ID']
  idoToken: Token
  meta: PoolMeta
  minOrderSizeTargetToken: Scalars['Decimal']
  network: BlockchainNetwork
  pipeline: PoolPipeline
  poolStatus: PoolStatus
  poolType: PoolType
  price?: Maybe<Scalars['Decimal']>
  priceReverse?: Maybe<Scalars['Decimal']>
  progress: PoolProgress
  stakingAddress: Scalars['Address']
  statistics: PoolStatistics
  targetToken?: Maybe<Token>
  tiers: Array<Tier>
  verifiedStatus: VerifiedStatus
  waves?: Maybe<PoolWaves>
  whitelistAddress: Scalars['Address']
}

export type PoolAccount = {
  __typename?: 'PoolAccount'
  account: Scalars['String']
  amountPaid: Scalars['Decimal']
  claimAmount: Scalars['Decimal']
  id: Scalars['ID']
  isRegistered: Scalars['Boolean']
  isWhitelisted?: Maybe<Scalars['Boolean']>
  refundAmount: Scalars['Decimal']
  tier: Scalars['Int']
  userToReserve: Scalars['Decimal']
  walletBalance: Scalars['Decimal']
  wave?: Maybe<Scalars['Int']>
  waveStartTime?: Maybe<Scalars['Time']>
}

export type PoolMeta = {
  __typename?: 'PoolMeta'
  audit?: Maybe<Scalars['String']>
  coolOffExplainedLink?: Maybe<Scalars['String']>
  description: Scalars['String']
  github?: Maybe<Scalars['String']>
  idoTokenPriceUSD: Scalars['Decimal']
  initialMarketCap: Scalars['Decimal']
  initialTokenInCirculation: Scalars['Decimal']
  listOfMembers?: Maybe<Array<Member>>
  logo: Scalars['String']
  logoBack: Scalars['String']
  logoFront: Scalars['String']
  medium?: Maybe<Scalars['String']>
  name: Scalars['String']
  participationExplainedLink?: Maybe<Scalars['String']>
  roughTokenPriceUSD: Scalars['Decimal']
  secondaryMarket?: Maybe<Scalars['String']>
  telegram?: Maybe<Scalars['String']>
  tiersExplainedLink?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  whitepaper?: Maybe<Scalars['String']>
}

export type PoolParticipationResponse = {
  __typename?: 'PoolParticipationResponse'
  reason?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type PoolPipeline = {
  __typename?: 'PoolPipeline'
  claimTime?: Maybe<Scalars['Time']>
  finishTime?: Maybe<Scalars['Time']>
  prelaunchTime?: Maybe<Scalars['Time']>
  registrationTime?: Maybe<Scalars['Time']>
  startTime?: Maybe<Scalars['Time']>
  whitelistingTime?: Maybe<Scalars['Time']>
}

export type PoolProgress = {
  __typename?: 'PoolProgress'
  softCapPercent?: Maybe<Scalars['Decimal']>
  tokensSoftCap?: Maybe<Scalars['Decimal']>
  tokensSold?: Maybe<Scalars['Decimal']>
  tokensToSell?: Maybe<Scalars['Decimal']>
  totalTarget?: Maybe<Scalars['Decimal']>
  totalTargetUSD?: Maybe<Scalars['Decimal']>
}

export type PoolStatistics = {
  __typename?: 'PoolStatistics'
  marketcapEstimate?: Maybe<Scalars['Decimal']>
  participantsCount: Scalars['Int']
  registeredCount: Scalars['Int']
}

export enum PoolStatus {
  ComingSoon = 'ComingSoon',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Paused = 'Paused',
  Prelaunch = 'Prelaunch',
  Registration = 'Registration',
  SoldOut = 'SoldOut',
  SuccessfullyFinished = 'SuccessfullyFinished',
  Whitelisting = 'Whitelisting',
}

export enum PoolType {
  FixedPricePool = 'FixedPricePool',
}

export type PoolWavePipeline = {
  __typename?: 'PoolWavePipeline'
  number: Scalars['Int']
  start: Scalars['Time']
}

export type PoolWaves = {
  __typename?: 'PoolWaves'
  currentWave?: Maybe<Scalars['Int']>
  pipeline: Array<PoolWavePipeline>
}

export type Query = {
  __typename?: 'Query'
  currentTime: Scalars['Time']
  dashboard: DashboardInfo
  getTransaction: TxState
  kycInfo: KycInfo
  pools: Array<Pool>
  stakingInfo: StakingInfo
}

export type QueryGetTransactionArgs = {
  session: Scalars['Session']
  txHash: Scalars['Hash']
}

export type QueryKycInfoArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type QueryPoolsArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingInfoArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type RegisterPoolParticipationInput = {
  poolFakeAddress: Scalars['String']
  session: Scalars['Session']
}

export type StakingInfo = {
  __typename?: 'StakingInfo'
  allowance: Scalars['Decimal']
  apy: Scalars['Decimal']
  rewardAvailable: Scalars['Decimal']
  rewardClaimed: Scalars['Decimal']
  rewardToken: Token
  rewardTotal: Scalars['Decimal']
  rewardTotalUSD: Scalars['Decimal']
  stakingBalance: Scalars['Decimal']
  stakingToken: Token
  totalEmmitedRewards: Scalars['Decimal']
  unstakingFeeRatio: Scalars['Decimal']
  walletBalance: Scalars['Decimal']
}

export type Tier = {
  __typename?: 'Tier'
  allocationMultiple: Scalars['Decimal']
  coolOff: Scalars['Int']
  isSpecial?: Maybe<Scalars['Boolean']>
  level: Scalars['Int']
  maxAllocation?: Maybe<Scalars['Decimal']>
  minStake?: Maybe<Scalars['Decimal']>
  name: Scalars['String']
  participationChanceFrom: Scalars['Int']
  participationChanceTo: Scalars['Int']
}

export type Token = {
  __typename?: 'Token'
  address: Scalars['Address']
  blockchain: Blockchain
  decimals: Scalars['Int']
  id: Scalars['ID']
  name: Scalars['String']
  symbol: Scalars['String']
  totalSupply: Scalars['Decimal']
}

export type Tx = {
  blockchain: Blockchain
  from: Scalars['Address']
  to: Scalars['Address']
}

export type TxEvent = {
  __typename?: 'TxEvent'
  contract: Scalars['String']
  data: Scalars['String']
  name: Scalars['String']
}

export type TxState = {
  __typename?: 'TxState'
  blockHash?: Maybe<Scalars['Hash']>
  blockNumber?: Maybe<Scalars['Int']>
  events?: Maybe<Array<TxEvent>>
  status: TxStatus
  txHash: Scalars['Hash']
}

export enum TxStatus {
  Empty = 'Empty',
  Failed = 'Failed',
  Pending = 'Pending',
  Success = 'Success',
}

export enum VerifiedStatus {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type AuthMutationVariables = Exact<{
  input: AuthInput
}>

export type AuthMutation = {
  __typename?: 'Mutation'
  auth: { __typename?: 'AuthResult'; session: string; address: string }
}

export type CreatePoolClaimTxMutationVariables = Exact<{
  input: CreatePoolClaimTxInput
}>

export type CreatePoolClaimTxMutation = {
  __typename?: 'Mutation'
  createPoolClaimTx: {
    __typename?: 'EthereumTx'
    blockchain: Blockchain
    from: string
    to: string
    chainId: string
    gas: string
    gasFeeCap: string
    gasTipCap: string
    gasPrice: string
    data: string
    nonce: string
    value: string
  }
}

export type CreatePoolRefundTxMutationVariables = Exact<{
  input: CreatePoolRefundTxInput
}>

export type CreatePoolRefundTxMutation = {
  __typename?: 'Mutation'
  createPoolRefundTx: {
    __typename?: 'EthereumTx'
    blockchain: Blockchain
    from: string
    to: string
    chainId: string
    gas: string
    gasFeeCap: string
    gasTipCap: string
    gasPrice: string
    data: string
    nonce: string
    value: string
  }
}

export type CreatePoolReserveTxMutationVariables = Exact<{
  input: CreatePoolReserveTxInput
}>

export type CreatePoolReserveTxMutation = {
  __typename?: 'Mutation'
  createPoolReserveTx: {
    __typename?: 'EthereumTx'
    blockchain: Blockchain
    from: string
    to: string
    chainId: string
    gas: string
    gasFeeCap: string
    gasTipCap: string
    gasPrice: string
    data: string
    nonce: string
    value: string
  }
}

export type CreateStakingApproveTxMutationVariables = Exact<{
  session: Scalars['Session']
  input: CreateStakingApproveTxInput
}>

export type CreateStakingApproveTxMutation = {
  __typename?: 'Mutation'
  createStakingApproveTx: {
    __typename?: 'EthereumTx'
    from: string
    to: string
    blockchain: Blockchain
    chainId: string
    gas: string
    gasFeeCap: string
    gasTipCap: string
    gasPrice: string
    data: string
    nonce: string
    value: string
  }
}

export type CreateStakingClaimRewardTxMutationVariables = Exact<{
  session: Scalars['Session']
  input: CreateStakingClaimRewardTxInput
}>

export type CreateStakingClaimRewardTxMutation = {
  __typename?: 'Mutation'
  createStakingClaimRewardTx: {
    __typename?: 'EthereumTx'
    from: string
    to: string
    blockchain: Blockchain
    chainId: string
    gas: string
    gasFeeCap: string
    gasTipCap: string
    gasPrice: string
    data: string
    nonce: string
    value: string
  }
}

export type CreateStakingStakeTxMutationVariables = Exact<{
  session: Scalars['Session']
  input: CreateStakingStakeTxInput
}>

export type CreateStakingStakeTxMutation = {
  __typename?: 'Mutation'
  createStakingStakeTx: {
    __typename?: 'EthereumTx'
    from: string
    to: string
    blockchain: Blockchain
    chainId: string
    gas: string
    gasFeeCap: string
    gasTipCap: string
    gasPrice: string
    data: string
    nonce: string
    value: string
  }
}

export type CreateStakingUnstakeTxMutationVariables = Exact<{
  session: Scalars['Session']
  input: CreateStakingUnstakeTxInput
}>

export type CreateStakingUnstakeTxMutation = {
  __typename?: 'Mutation'
  createStakingUnstakeTx: {
    __typename?: 'EthereumTx'
    from: string
    to: string
    blockchain: Blockchain
    chainId: string
    gas: string
    gasFeeCap: string
    gasTipCap: string
    gasPrice: string
    data: string
    nonce: string
    value: string
  }
}

export type EthereumTxFragment = {
  __typename?: 'EthereumTx'
  chainId: string
  gas: string
  gasFeeCap: string
  gasTipCap: string
  gasPrice: string
  data: string
  nonce: string
  value: string
}

export type GetAuthMessageMutationVariables = Exact<{
  address: Scalars['Address']
}>

export type GetAuthMessageMutation = {
  __typename?: 'Mutation'
  getAuthMessage: string
}

export type RegisterPoolParticipationMutationVariables = Exact<{
  input: RegisterPoolParticipationInput
}>

export type RegisterPoolParticipationMutation = {
  __typename?: 'Mutation'
  registerPoolParticipation: {
    __typename?: 'PoolParticipationResponse'
    reason?: string | null | undefined
    success: boolean
  }
}

export type GeneralInfoQueryVariables = Exact<{
  session?: Maybe<Scalars['Session']>
}>

export type GeneralInfoQuery = {
  __typename?: 'Query'
  pools: Array<{
    __typename?: 'FixedPricePool'
    address: string
    blockchain: Blockchain
    currentTime: string
    id: string
    minOrderSizeTargetToken: string
    network: BlockchainNetwork
    poolStatus: PoolStatus
    poolType: PoolType
    price?: string | null | undefined
    priceReverse?: string | null | undefined
    stakingAddress: string
    verifiedStatus: VerifiedStatus
    whitelistAddress: string
    account?:
      | {
          __typename?: 'PoolAccount'
          account: string
          amountPaid: string
          claimAmount: string
          id: string
          isRegistered: boolean
          isWhitelisted?: boolean | null | undefined
          refundAmount: string
          tier: number
          userToReserve: string
          walletBalance: string
          wave?: number | null | undefined
          waveStartTime?: string | null | undefined
        }
      | null
      | undefined
    idoToken: {
      __typename?: 'Token'
      id: string
      blockchain: Blockchain
      address: string
      totalSupply: string
      decimals: number
      symbol: string
    }
    meta: {
      __typename?: 'PoolMeta'
      audit?: string | null | undefined
      coolOffExplainedLink?: string | null | undefined
      description: string
      github?: string | null | undefined
      idoTokenPriceUSD: string
      initialMarketCap: string
      initialTokenInCirculation: string
      logo: string
      logoBack: string
      logoFront: string
      medium?: string | null | undefined
      name: string
      participationExplainedLink?: string | null | undefined
      roughTokenPriceUSD: string
      secondaryMarket?: string | null | undefined
      telegram?: string | null | undefined
      tiersExplainedLink?: string | null | undefined
      twitter?: string | null | undefined
      website?: string | null | undefined
      whitepaper?: string | null | undefined
      listOfMembers?:
        | Array<{
            __typename?: 'Member'
            avatar: string
            linkedIn: string
            name: string
            position: string
          }>
        | null
        | undefined
    }
    pipeline: {
      __typename?: 'PoolPipeline'
      claimTime?: string | null | undefined
      finishTime?: string | null | undefined
      startTime?: string | null | undefined
      prelaunchTime?: string | null | undefined
      registrationTime?: string | null | undefined
      whitelistingTime?: string | null | undefined
    }
    progress: {
      __typename?: 'PoolProgress'
      softCapPercent?: string | null | undefined
      tokensSoftCap?: string | null | undefined
      tokensSold?: string | null | undefined
      tokensToSell?: string | null | undefined
      totalTarget?: string | null | undefined
      totalTargetUSD?: string | null | undefined
    }
    statistics: {
      __typename?: 'PoolStatistics'
      participantsCount: number
      registeredCount: number
    }
    targetToken?:
      | {
          __typename?: 'Token'
          id: string
          address: string
          totalSupply: string
          decimals: number
          symbol: string
        }
      | null
      | undefined
    tiers: Array<{
      __typename?: 'Tier'
      allocationMultiple: string
      coolOff: number
      isSpecial?: boolean | null | undefined
      level: number
      maxAllocation?: string | null | undefined
      minStake?: string | null | undefined
      name: string
      participationChanceFrom: number
      participationChanceTo: number
    }>
    waves?:
      | {
          __typename?: 'PoolWaves'
          currentWave?: number | null | undefined
          pipeline: Array<{
            __typename?: 'PoolWavePipeline'
            number: number
            start: string
          }>
        }
      | null
      | undefined
  }>
  stakingInfo: {
    __typename?: 'StakingInfo'
    stakingBalance: string
    walletBalance: string
    allowance: string
    apy: string
    unstakingFeeRatio: string
    totalEmmitedRewards: string
    rewardAvailable: string
    rewardClaimed: string
    rewardTotal: string
    rewardTotalUSD: string
    stakingToken: { __typename?: 'Token'; symbol: string; decimals: number }
    rewardToken: { __typename?: 'Token'; symbol: string; decimals: number }
  }
  dashboard: {
    __typename?: 'DashboardInfo'
    PTPerDayInCurrentEpoch: {
      __typename?: 'Balance'
      amount: string
      token: { __typename?: 'Token'; symbol: string }
    }
    RequiredTTStakedForCalculation: {
      __typename?: 'Balance'
      amount: string
      token: { __typename?: 'Token'; symbol: string }
    }
    RTConditionedYield: {
      __typename?: 'Balance'
      amount: string
      token: { __typename?: 'Token'; symbol: string }
    }
    TTStaked: {
      __typename?: 'Balance'
      amount: string
      token: { __typename?: 'Token'; symbol: string }
    }
    PTInitialPrice: {
      __typename?: 'Balance'
      amount: string
      token: { __typename?: 'Token'; symbol: string }
    }
    CirculatingSupplyOfRT: {
      __typename?: 'Balance'
      amount: string
      token: { __typename?: 'Token'; symbol: string }
    }
    TotalSupplyOfRT: {
      __typename?: 'Balance'
      amount: string
      token: { __typename?: 'Token'; symbol: string }
    }
  }
}

export type TransactionQueryVariables = Exact<{
  session: Scalars['Session']
  txHash: Scalars['Hash']
}>

export type TransactionQuery = {
  __typename?: 'Query'
  getTransaction: {
    __typename?: 'TxState'
    txHash: string
    status: TxStatus
    events?:
      | Array<{
          __typename?: 'TxEvent'
          contract: string
          data: string
          name: string
        }>
      | null
      | undefined
  }
}

export const EthereumTxFragmentDoc = gql`
  fragment ethereumTx on EthereumTx {
    chainId
    gas
    gasFeeCap
    gasTipCap
    gasPrice
    data
    nonce
    value
    chainId
  }
`
export const AuthDocument = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      session
      address
    }
  }
`
export const CreatePoolClaimTxDocument = gql`
  mutation CreatePoolClaimTx($input: CreatePoolClaimTxInput!) {
    createPoolClaimTx(input: $input) {
      blockchain
      from
      to
      ...ethereumTx
    }
  }
  ${EthereumTxFragmentDoc}
`
export const CreatePoolRefundTxDocument = gql`
  mutation CreatePoolRefundTx($input: CreatePoolRefundTxInput!) {
    createPoolRefundTx(input: $input) {
      blockchain
      from
      to
      ...ethereumTx
    }
  }
  ${EthereumTxFragmentDoc}
`
export const CreatePoolReserveTxDocument = gql`
  mutation CreatePoolReserveTx($input: CreatePoolReserveTxInput!) {
    createPoolReserveTx(input: $input) {
      blockchain
      from
      to
      ...ethereumTx
    }
  }
  ${EthereumTxFragmentDoc}
`
export const CreateStakingApproveTxDocument = gql`
  mutation CreateStakingApproveTx(
    $session: Session!
    $input: CreateStakingApproveTxInput!
  ) {
    createStakingApproveTx(session: $session, input: $input) {
      from
      to
      blockchain
      ...ethereumTx
    }
  }
  ${EthereumTxFragmentDoc}
`
export const CreateStakingClaimRewardTxDocument = gql`
  mutation CreateStakingClaimRewardTx(
    $session: Session!
    $input: CreateStakingClaimRewardTxInput!
  ) {
    createStakingClaimRewardTx(session: $session, input: $input) {
      from
      to
      blockchain
      ...ethereumTx
    }
  }
  ${EthereumTxFragmentDoc}
`
export const CreateStakingStakeTxDocument = gql`
  mutation CreateStakingStakeTx(
    $session: Session!
    $input: CreateStakingStakeTxInput!
  ) {
    createStakingStakeTx(session: $session, input: $input) {
      from
      to
      blockchain
      ...ethereumTx
    }
  }
  ${EthereumTxFragmentDoc}
`
export const CreateStakingUnstakeTxDocument = gql`
  mutation CreateStakingUnstakeTx(
    $session: Session!
    $input: CreateStakingUnstakeTxInput!
  ) {
    createStakingUnstakeTx(session: $session, input: $input) {
      from
      to
      blockchain
      ...ethereumTx
    }
  }
  ${EthereumTxFragmentDoc}
`
export const GetAuthMessageDocument = gql`
  mutation GetAuthMessage($address: Address!) {
    getAuthMessage(address: $address)
  }
`
export const RegisterPoolParticipationDocument = gql`
  mutation RegisterPoolParticipation($input: RegisterPoolParticipationInput!) {
    registerPoolParticipation(input: $input) {
      reason
      success
    }
  }
`
export const GeneralInfoDocument = gql`
  query GeneralInfo($session: Session) {
    pools(session: $session) {
      account {
        account
        amountPaid
        claimAmount
        id
        isRegistered
        isWhitelisted
        refundAmount
        tier
        userToReserve
        walletBalance
        wave
        waveStartTime
      }
      address
      blockchain
      currentTime
      id
      idoToken {
        id
        blockchain
        address
        totalSupply
        decimals
        symbol
        decimals
      }
      meta {
        audit
        coolOffExplainedLink
        description
        github
        idoTokenPriceUSD
        initialMarketCap
        initialTokenInCirculation
        listOfMembers {
          avatar
          linkedIn
          name
          position
        }
        logo
        logoBack
        logoFront
        medium
        name
        participationExplainedLink
        roughTokenPriceUSD
        secondaryMarket
        telegram
        tiersExplainedLink
        twitter
        website
        whitepaper
      }
      minOrderSizeTargetToken
      network
      pipeline {
        claimTime
        finishTime
        startTime
        prelaunchTime
        registrationTime
        whitelistingTime
      }
      poolStatus
      poolType
      price
      priceReverse
      progress {
        softCapPercent
        tokensSoftCap
        tokensSold
        tokensToSell
        totalTarget
        totalTargetUSD
      }
      stakingAddress
      statistics {
        participantsCount
        registeredCount
      }
      targetToken {
        id
        address
        totalSupply
        decimals
        symbol
        decimals
      }
      tiers {
        allocationMultiple
        coolOff
        isSpecial
        level
        maxAllocation
        minStake
        name
        participationChanceFrom
        participationChanceTo
      }
      verifiedStatus
      waves {
        currentWave
        pipeline {
          number
          start
        }
      }
      whitelistAddress
    }
    stakingInfo(session: $session) {
      stakingToken {
        symbol
        decimals
      }
      stakingBalance
      walletBalance
      allowance
      apy
      unstakingFeeRatio
      totalEmmitedRewards
      rewardToken {
        symbol
        decimals
      }
      rewardAvailable
      rewardClaimed
      rewardTotal
      rewardTotalUSD
    }
    dashboard {
      PTPerDayInCurrentEpoch {
        token {
          symbol
        }
        amount
      }
      RequiredTTStakedForCalculation {
        token {
          symbol
        }
        amount
      }
      RTConditionedYield {
        token {
          symbol
        }
        amount
      }
      TTStaked {
        token {
          symbol
        }
        amount
      }
      PTInitialPrice {
        token {
          symbol
        }
        amount
      }
      CirculatingSupplyOfRT {
        token {
          symbol
        }
        amount
      }
      TotalSupplyOfRT {
        token {
          symbol
        }
        amount
      }
    }
  }
`
export const TransactionDocument = gql`
  query Transaction($session: Session!, $txHash: Hash!) {
    getTransaction(session: $session, txHash: $txHash) {
      txHash
      status
      events {
        contract
        data
        name
      }
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    Auth(
      variables: AuthMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AuthMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuthMutation>(AuthDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Auth'
      )
    },
    CreatePoolClaimTx(
      variables: CreatePoolClaimTxMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreatePoolClaimTxMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreatePoolClaimTxMutation>(
            CreatePoolClaimTxDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CreatePoolClaimTx'
      )
    },
    CreatePoolRefundTx(
      variables: CreatePoolRefundTxMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreatePoolRefundTxMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreatePoolRefundTxMutation>(
            CreatePoolRefundTxDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CreatePoolRefundTx'
      )
    },
    CreatePoolReserveTx(
      variables: CreatePoolReserveTxMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreatePoolReserveTxMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreatePoolReserveTxMutation>(
            CreatePoolReserveTxDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CreatePoolReserveTx'
      )
    },
    CreateStakingApproveTx(
      variables: CreateStakingApproveTxMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreateStakingApproveTxMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateStakingApproveTxMutation>(
            CreateStakingApproveTxDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CreateStakingApproveTx'
      )
    },
    CreateStakingClaimRewardTx(
      variables: CreateStakingClaimRewardTxMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreateStakingClaimRewardTxMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateStakingClaimRewardTxMutation>(
            CreateStakingClaimRewardTxDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CreateStakingClaimRewardTx'
      )
    },
    CreateStakingStakeTx(
      variables: CreateStakingStakeTxMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreateStakingStakeTxMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateStakingStakeTxMutation>(
            CreateStakingStakeTxDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CreateStakingStakeTx'
      )
    },
    CreateStakingUnstakeTx(
      variables: CreateStakingUnstakeTxMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreateStakingUnstakeTxMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateStakingUnstakeTxMutation>(
            CreateStakingUnstakeTxDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CreateStakingUnstakeTx'
      )
    },
    GetAuthMessage(
      variables: GetAuthMessageMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetAuthMessageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAuthMessageMutation>(
            GetAuthMessageDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetAuthMessage'
      )
    },
    RegisterPoolParticipation(
      variables: RegisterPoolParticipationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<RegisterPoolParticipationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RegisterPoolParticipationMutation>(
            RegisterPoolParticipationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'RegisterPoolParticipation'
      )
    },
    GeneralInfo(
      variables?: GeneralInfoQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GeneralInfoQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GeneralInfoQuery>(GeneralInfoDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GeneralInfo'
      )
    },
    Transaction(
      variables: TransactionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TransactionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TransactionQuery>(TransactionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Transaction'
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
