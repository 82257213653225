import { RouteComponentProps } from '@reach/router'
import { Header } from 'components'
import Modals from 'components/Modals'
import noop from 'lodash/noop'
import { useEffect } from 'react'

interface Props extends RouteComponentProps {
  children?: React.ReactNode
}

function Main(props: Props) {
  const { location, navigate = noop } = props
  const path = props.location?.pathname ?? ''
  const isStakingPage = path === '/staking'
  const isHomePage = path === '/'
  const isDashboardPage = path === '/dashboard'
  const isIdoPage = path.startsWith('/project/') || path === '/ido'
  const singlePoolAddress = process.env.REACT_APP_SINGLE_POOL_ADDRESS

  useEffect(() => {
    if (location?.search.match(/__cf_chl_captcha_tk__/)) {
      location.search = ''
      navigate(location.toString())
    }
  }, [location?.search, location, navigate])

  return (
    <div className="relative flex justify-center w-screen h-screen m-auto">
      <div className="relative text-primary w-full">
        <div
          className={`absolute z-[-1] w-full bg-top bg-no-repeat bg-cover ${
            isStakingPage
              ? 'bg-staking-bg transform h-screen -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 hidden m:block l:top-24px m:bg-mSize l:bg-lSize xl:bg-xlSize m:bg-topL l:bg-topL xl:bg-top0'
              : 'bg-darkOrange'
          }
          ${
            isHomePage && !singlePoolAddress
              ? 'bg-fixed mask-main-page-bg h-screen  bg-home-page-bg-mobile xs:bg-home-page-bg'
              : 'bg-darkOrange'
          } 
          ${
            isIdoPage
              ? 'bg-fixed  mask-main-page-bg bg-ido-page-bg h-screen'
              : 'bg-darkOrange'
          }
          ${isDashboardPage ? ' bg-dashboardBgDark' : 'bg-darkOrange'}
          `}
        />
        <Header
          navActiveColor={`${isDashboardPage ? 'text-accentSecondary' : ''}`}
          gradient={`${
            isDashboardPage ? 'bg-gradient-header-accent-secondary' : ''
          }`}
        />
        {isHomePage && (
          <div className="fixed top-0 z-[-2] bg-home-page-bg-texture bg-cover bg-center w-full h-full bg-repeat-y"></div>
        )}
        {isDashboardPage && (
          <div className="fixed top-0 z-[-1] bg-dashboard-page-bg bg-cover bg-center w-full h-full bg-no-repeat"></div>
        )}
        <div className="m-auto pb-8 w-[90%] xs:w-[390px] s:w-[580px] m:w-[900px] l:w-[1110px] xl:w-[1440px]">
          {props.children}
        </div>
      </div>
      <Modals />
    </div>
  )
}

export default Main
