import Button from 'components/Button'
import { authFx } from 'models/user'
import { t } from 'utils'

export default function ConnectMetamaskModal() {
  const handleClick = () => {
    authFx()
  }
  return (
    <div className="flex flex-col items-center flex-grow px-12">
      <p className="my-4 text-[1.625rem] leading-none font-semibold mb-4">
        {t('connect_metamask')}
      </p>
      <span className="flex mb-8 text-sm">
        {t('terms_of_use_msg', {
          link: (
            <a
              href={`${process.env.PUBLIC_URL}/pdf/L2Pad.io Terms & Conditions.pdf`}
              target="_blank"
              key="1"
              className="ml-1 underline cursor-pointer text-accent hover:no-underline"
              rel="noreferrer"
            >
              {' '}
              {t('terms_of_use')}
            </a>
          ),
        })}
      </span>
      <Button className="w-full uppercase" onClick={handleClick}>
        Connect
      </Button>
    </div>
  )
}
