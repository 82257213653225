import Button from 'components/Button'
import { ReactComponent as CrossIcon } from 'icons/cross-error.svg'
import { closeModal } from 'models/flow'
import { t } from 'utils'

export default function FailureModal() {
  const handleClick = () => {
    closeModal()
  }
  return (
    <div className="flex flex-col items-center flex-grow px-14">
      <p className="text-[1.625rem] leading-none font-semibold mb-2.5">
        {t('failed')}
      </p>
      <p className="text-sm leading-none whitespace-nowrap">
        {t('something_wrong')}
      </p>
      <div className="mt-8 mb-10">
        <CrossIcon width="3em" height="3em" />
      </div>
      <Button className="w-full uppercase" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
