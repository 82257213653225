import { combine, createEffect, createEvent, restore } from 'effector'
import { Blockchain, BlockchainNetwork, GeneralInfoQuery } from 'gqlgen/public'
import get from 'lodash/get'
import { $user } from 'models/user'
import { chainIds, graphqlSdk } from 'utils/consts'
import { toDecimal } from 'utils/numbers'

// Dashboard
const emptyDashboard: GeneralInfoQuery['dashboard'] | null = null
export const setDashboard = createEvent<GeneralInfoQuery['dashboard']>()
export const $dashboard = restore(setDashboard, emptyDashboard)

// Staking
export const emptyStakingInfo: GeneralInfoQuery['stakingInfo'] = {
  allowance: '0',
  apy: '0',
  unstakingFeeRatio: '4',
  // @ts-ignore
  stakingToken: {
    symbol: 'L2PAD',
  },
  stakingBalance: '0',
  walletBalance: '0',

  // @ts-ignore
  rewardToken: {
    symbol: 'L2POOL',
  },
  rewardAvailable: '0',
  rewardClaimed: '0',
  rewardTotal: '0',
  rewardTotalUSD: '0',
}
export const setStakingInfo = createEvent<GeneralInfoQuery['stakingInfo']>()
export const $stakingInfo = restore(setStakingInfo, emptyStakingInfo)
export const $stakingAllowed = $stakingInfo.map(
  ({ allowance, walletBalance }) =>
    toDecimal(allowance).greaterThanOrEqualTo(toDecimal(walletBalance)) //.greaterThan(0)
)

// Pools
export const setPools = createEvent<GeneralInfoQuery['pools']>()
export const setIdoCurrentPoolName = createEvent<string>()
export const setIdoCurrentPoolAddress = createEvent<string>()
export const setIdoCurrentPoolNetwork = createEvent<BlockchainNetwork>()

export const $pools = restore(setPools, [])

export const $idoCurrentPoolName = restore(setIdoCurrentPoolName, '')
export const $idoCurrentPoolAddress = restore(setIdoCurrentPoolAddress, '')
export const $idoCurrentPoolNetwork = restore(
  setIdoCurrentPoolNetwork,
  BlockchainNetwork.ArbitrumMainnet
)

// Blockchain & Network
export const setBlockchain = createEvent<Blockchain>()
export const $blockchain = restore(setBlockchain, Blockchain.Arbitrum)

export const setNetwork = createEvent<BlockchainNetwork>()
export const $network = restore(setNetwork, BlockchainNetwork.ArbitrumMainnet)

export const $chainId = combine($blockchain, $network).map(
  ([blockchain, network]) =>
    get(chainIds, [blockchain, network], chainIds.Arbitrum.ArbitrumMainnet)
)

export const fetchGeneralInfoFx = createEffect(async () => {
  const { session } = $user.getState()
  try {
    const { pools, stakingInfo, dashboard } = await graphqlSdk.GeneralInfo({
      session,
    })
    setPools(pools)
    setStakingInfo(stakingInfo)
    setDashboard(dashboard)
  } catch (err) {
    // todo: report error
  }
})
