import { Hash } from 'components'
import Button from 'components/Button'
import { useStore } from 'effector-react'
import { ReactComponent as CrossIcon } from 'icons/cross-error.svg'
import { $idoCurrentPoolNetwork } from 'models/app'
import { $txHash, closeModal } from 'models/flow'
import { t } from 'utils'
import { poolAddressesLinks } from 'utils/consts'

export default function TxFailureModal() {
  const hash = useStore($txHash)
  const network = useStore($idoCurrentPoolNetwork)

  const handleClick = () => {
    closeModal()
  }
  const url = `${poolAddressesLinks[network]}/tx/${hash}`

  return (
    <div className="flex flex-col items-center flex-grow px-14">
      <p className="text-[1.625rem] leading-none font-semibold mb-2.5">
        {t('transaction_failed')}
      </p>
      <div className="text-sm leading-none whitespace-nowrap">
        <Hash address={hash} url={url} />
      </div>
      <p className="text-sm leading-none whitespace-nowrap">
        {t('something_wrong')}
      </p>
      <div className="mt-8 mb-10">
        <CrossIcon width="3em" height="3em" />
      </div>
      <Button className="w-full uppercase" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
