import Button from 'components/Button'
import { useStore } from 'effector-react'
import { $idoCurrentPoolName } from 'models/app'
import { registrationFx } from 'models/flow'
import { t } from 'utils'

export default function RegistrationSuccessModal() {
  const idoCurrentPoolName = useStore($idoCurrentPoolName)

  const handleClick = () => {
    registrationFx()
  }

  return (
    <div className="flex flex-col items-center flex-grow px-14">
      <div className="text-2xl font-bold text-accent">{idoCurrentPoolName}</div>
      <p className="my-4 text-[1.625rem] leading-none font-semibold mb-4">
        {t('confirm_registration_title')}
      </p>
      <ul className="list-disc ml-2 mt-8 mb-4">
        <li className="mb-4">
          Finalize your tier before the whitelisting process begins.
        </li>
        <li className="mb-4">
          Stake your tokens till the end of the IDO, otherwise we can’t
          guarantee correct whitelisting and allocation for your address.
        </li>
        <li className="mb-4">
          Cool-off period is applied to some of the whitelisted users.
        </li>
      </ul>
      <Button className="w-full uppercase" onClick={handleClick}>
        {t('confirm_registration')}
      </Button>
    </div>
  )
}
