import { createEffect, createEvent, createStore } from 'effector'
import get from 'lodash/get'
import { $chainId } from 'models/app'
import {
  closeModal,
  openFailureModal,
  openMetamaskFailureModal,
  openMetamaskWaitingModal,
  openWaitingModal,
} from 'models/flow'
import { isMobile } from 'react-device-detect'
import { chainInfo, graphqlSdk } from 'utils/consts'
import { solveSign } from 'utils/sign'

const address = localStorage.getItem('address') ?? '' // todo: replace with effector-storage
const session = localStorage.getItem(address) ?? '' // todo: replace with effector-storage

const emptyUser = {
  session,
  address,
}

export const clock = createEvent()
export const setNetworkIsOk = createEvent<boolean>()

export const setUserAddress = createEvent<string>()
export const setUserSession = createEvent<string>()
export const resetUserSession = createEvent()
export const resetUser = createEvent()
export const logoutUser = createEvent()

export const $user = createStore(emptyUser)
export const $authOk = $user.map((user) => user.session !== '')
export const $networkIsOk = createStore(false) // todo

export const loadUserFx = createEffect(async () => {
  if (!window.ethereum?.isMetaMask) {
    throw new Error('No metamask has been found')
  }

  const addresses = await window.ethereum?.request({
    method: 'eth_requestAccounts',
  })

  if (!Array.isArray(addresses) || addresses.length === 0) {
    throw new Error('Addresses are empty')
  }

  const address = `${get(addresses, 0, '')}`
  setUserAddress(address)
})

const _authWithMetamask = async () => {
  if (!window.ethereum?.isMetaMask) return

  const adds = await window.ethereum?.request({
    method: 'eth_requestAccounts',
  })
  if (!Array.isArray(adds) || adds.length === 0) return

  const authMessageData = await graphqlSdk.GetAuthMessage({
    address: adds[0],
  })
  const authMessage = authMessageData?.getAuthMessage ?? ''

  let sign = await window.ethereum?.request({
    method: 'personal_sign',
    from: adds[0],
    params: [adds[0], authMessage],
  })

  try {
    let authData = await graphqlSdk.Auth({
      input: { sign: solveSign(sign), authMessage: authMessage },
    })

    if (authData?.auth.session) {
      setUserSession(`${authData?.auth.session}`)
    } else {
      resetUserSession()
    }
  } catch (err) {
    console.error(err)
  }
}

export const authFx = createEffect(async () => {
  openMetamaskWaitingModal()

  if (!!window.ethereum?.isMetaMask) {
    try {
      await _authWithMetamask()
      closeModal()
      return true
    } catch (err) {
      openMetamaskFailureModal()
      return false
    }
  } else if (isMobile) {
    window.open(process.env.REACT_APP_DAPP_URL, '_blank')
    return false
  } else {
    window.open('https://metamask.io', '_blank', 'noopener,noreferrer')
    return false
  }
})

export const switchNetworkFx = createEffect(async () => {
  const chainId = $chainId.getState()
  const chain = get(chainInfo, chainId, chainInfo['0xa4b1'])

  if (chain) {
    openWaitingModal()
    try {
      await window.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      })
      closeModal()
    } catch (e: any) {
      // if (e.code === 4902) {
      try {
        await window.ethereum?.request({
          method: 'wallet_addEthereumChain',
          params: [chain],
        })
        closeModal()
      } catch (addError) {
        console.error(addError)
        openFailureModal()
      }
      // } else {
      //   openFailureModal()
      // }
    }
  }
})

export const addL2PADTokenFx = createEffect(async () => {
  const tokenAddress = '0x6Ba4edd6dB54eD34d53D8d8883E599C4dba009fb'
  const tokenSymbol = 'L2PAD'
  const tokenDecimals = 18
  const tokenImage = 'https://app.l2pad.io/mstile-310x310.png'

  // openWaitingModal()
  try {
    const wasAdded = await window.ethereum?.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: tokenImage,
        },
      },
    })

    if (wasAdded) {
      // closeModal()
    } else {
      openFailureModal()
    }
  } catch (error) {
    console.error(error)
    openFailureModal()
  }
})
