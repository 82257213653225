export function solveSign(result: string): string {
  let sign = result
  const m = sign.replace('0x', '').split('')
  let V = parseInt(`${m[128]}${m[129]}`, 16)
  if (V === 0 || V === 1) {
    V = (V + 27) % 256
  }
  const Vs = V.toString(16)
  if (Vs.length === 1) {
    m[128] = '0'
    m[129] = Vs[0]
  } else {
    m[128] = Vs[0]
    m[129] = Vs[1]
  }
  sign = '0x' + m.join('')
  return sign
}
