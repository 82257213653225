import { BlockchainNetwork } from 'gqlgen/public'
import { find } from 'lodash'
import { $pools, fetchGeneralInfoFx, setNetwork } from '.'

fetchGeneralInfoFx()
setInterval(fetchGeneralInfoFx, 5000)

// Выбираем правильный chainId на основе пула
$pools.watch((pools) => {
  const singlePoolAddress = process.env.REACT_APP_SINGLE_POOL_ADDRESS
  const singlePoolMode = !!singlePoolAddress

  if (singlePoolMode) {
    const pool = find(pools, { id: singlePoolAddress })

    // todo: blockchain if needed

    switch (pool?.network) {
      case BlockchainNetwork.ArbitrumTestnet:
        setNetwork(BlockchainNetwork.ArbitrumTestnet)
        break
      case BlockchainNetwork.ArbitrumMainnet:
      default:
        setNetwork(BlockchainNetwork.ArbitrumMainnet)
        break
    }
  }
})
