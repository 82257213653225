import { formatNumber } from 'utils/numbers'

interface Props {
  have: string
  total?: string
  currency?: string
}

export default function ProjectProgress({ have, total, currency }: Props) {
  const percent = (Number(have) * 100) / Number(total)

  return (
    <div className="text-sm">
      <div className="mb-1 flex justify-between relative">
        <div className="flex">
          {total ? (
            <>
              <div className="mr-2">Progress</div>
              {percent > 0 && (
                <div className="text-accent">
                  {formatNumber(percent, { fractionDigits: 2 })}%
                </div>
              )}
            </>
          ) : (
            'TBA'
          )}
        </div>
        {total ? (
          <div>
            {have}/{total} {currency}
          </div>
        ) : (
          'TBA'
        )}
      </div>
      <div className="flex w-full">
        <div className="relative w-full">
          <div
            className="relative z-10 h-2 bg-project-progress shadow-allocation-progress rounded-lg"
            style={{ width: `${percent !== 0 && total ? percent : '0.5'}%` }}
          ></div>
          <div className="absolute z-0 top-0 bg-black bg-opacity-35 w-full h-2 rounded-lg"></div>
        </div>
      </div>
    </div>
  )
}
