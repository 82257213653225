import { Status, InfoPopover } from 'components'

interface Props {
  status?: string
  bg?: string
  logo?: string
  live?: boolean
}

export default function ProjectHeader({
  status,
  bg,
  logo,
  live = false,
}: Props) {
  return (
    <div
      className="relative w-full h-[11.25rem] rounded-lg bg-center"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="absolute bottom-2 left-2 p-4 bg-black bg-opacity-50 rounded-lg">
        <img className=" max-h-16 max-w-[10rem]" src={logo} alt="" />
      </div>
      {live && (
        <div className="absolute top-4 left-4">
          <InfoPopover
            text={status}
            positions={['top', 'bottom']}
            className="w-auto p-2"
          >
            <div className="relative">
              <div className="animate-ping-slow absolute h-full w-full rounded-full bg-accent opacity-70"></div>
              <div className="relative rounded-full h-[10px] w-[10px] bg-accent"></div>
            </div>
          </InfoPopover>
        </div>
      )}

      {status && (
        <div className="absolute top-2 right-2">
          <Status text={status} />
        </div>
      )}
    </div>
  )
}
