import { Hash } from 'components'
import Loader from 'components/Loader'
import { useStore } from 'effector-react'
import { $idoCurrentPoolNetwork } from 'models/app'
import { $txHash } from 'models/flow'
import { t } from 'utils'
import { poolAddressesLinks } from 'utils/consts'

export default function TxWaitModal() {
  const hash = useStore($txHash)
  const network = useStore($idoCurrentPoolNetwork)

  const url = `${poolAddressesLinks[network]}/tx/${hash}`

  return (
    <div className="flex flex-col items-center flex-grow px-8">
      <p className="my-4 text-[1.625rem] leading-none font-semibold mb-4">
        {t('waiting_for_transaction_confirmation')}
      </p>
      {hash !== '' ? (
        <div className="text-sm leading-none whitespace-nowrap">
          <Hash address={hash} url={url} />
        </div>
      ) : null}
      <Loader />
    </div>
  )
}
