import { ReactComponent as IconLink } from 'icons/link-icon.svg'
import { truncateAddress } from 'utils'

interface Props {
  address: string
  url: string
}

export default function Hash({ address, url }: Props) {
  return (
    <>
      <div className="flex">
        <span className="mr-2 text-xs text-accent">
          {truncateAddress(address, 20)}
        </span>
        <a
          href={url}
          className="cursor-pointer "
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <IconLink width="0.9rem" height="0.9rem" />
        </a>
      </div>
    </>
  )
}
