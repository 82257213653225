interface Props {
  text: string
}

export default function Field({ text = 'Coming soon' }: Props) {
  return (
    <div className="p-2 font-bold inline-block rounded-lg text-accent bg-darkOrange">
      {text}
    </div>
  )
}
