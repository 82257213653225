import { Button } from 'components'
import NavLink from './NavLink'
import { ReactComponent as IconMetamask } from 'icons/metamask.svg'
import { ReactComponent as IconWarning } from 'icons/warning-icon.svg'
import { ReactComponent as IconMenu } from 'icons/menu.svg'
import { ReactComponent as IconMenuClose } from 'icons/menu-close.svg'

import { useNavigate } from '@reach/router'
import { useStore } from 'effector-react'
import {
  $authOk,
  $user,
  $networkIsOk,
  resetUserSession,
  switchNetworkFx,
} from 'models/user'
import { t, truncateAddress } from 'utils'
import { openConnectMetamaskModal } from 'models/flow'
import { useState } from 'react'

const URLS_MAP = [
  {
    url: '/ido',
    text: 'IDOs',
  },
  {
    url: '/staking',
    text: 'L2PAD Staking',
  },
  {
    url: '/dashboard',
    text: 'L2POOL Dashboard',
  },
  // {
  //   url: '/liquidity-mining',
  //   text: 'Liquidity Mining',
  // },
]

type Props = {
  navActiveColor?: string
  gradient?: string
}

export default function Header({ navActiveColor = '', gradient = '' }: Props) {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const openMenu = () => {
    setMenuIsOpen(true)
  }
  const closeMenu = () => {
    setMenuIsOpen(false)
  }

  const navigate = useNavigate()

  const { address } = useStore($user)
  const authOk = useStore($authOk)
  const networkIsOk = useStore($networkIsOk)

  const addr = truncateAddress(address, 4)

  const label = authOk
    ? `${t('logout').toUpperCase()} (${addr})`
    : t('connect_metamask').toUpperCase()

  const handleMetamaskClick = () => {
    if (authOk) {
      resetUserSession()
    } else {
      openConnectMetamaskModal()
    }
  }

  const handleSwitchNetwork = () => {
    switchNetworkFx()
  }
  return (
    <div className="relative">
      <header className="relative z-50">
        <div
          className={`absolute top-0 z-[0] w-full h-full bg-gradient-header-accent ${gradient}`}
        />
        <div className="relative flex justify-between py-8 z-2 m-auto w-[90%] xs:w-[390px] s:w-[580px] m:w-[900px] l:w-[1110px] xl:w-[1440px]">
          <div className="flex items-center space-x-10">
            <div
              className="mr-6"
              onClick={() => {
                navigate('/')
                closeMenu()
              }}
            >
              <img
                className="h-auto w-[10rem] cursor-pointer"
                src="/images/l2dao_logo.svg"
                alt="L2PAD"
              />
            </div>
            {URLS_MAP.map((item) => (
              <NavLink
                navActiveColor={navActiveColor}
                key={`nav-${item.url}`}
                to={item.url}
                text={item.text}
                desktop={true}
              />
            ))}
          </div>
          <div className="hidden m:flex relative">
            <Button
              className="mr-4"
              onClick={() =>
                window.open('https://bridge.arbitrum.io/', '_blank')
              }
            >
              <div className="flex items-center">
                <div className="leading-none text-white">
                  {t('bridge').toUpperCase()}
                </div>
              </div>
            </Button>
            {!networkIsOk && (
              <Button className="mr-4" onClick={handleSwitchNetwork}>
                <div className="flex items-center">
                  <div className="leading-none text-white">
                    {t('switch_to_arbitrum').toUpperCase()}
                  </div>
                </div>
              </Button>
            )}
            <Button
              className={`${
                !networkIsOk
                  ? 'border border-borderGray bg-gray bg-opacity-30'
                  : ''
              }`}
              onClick={handleMetamaskClick}
            >
              <div className="flex items-center">
                {networkIsOk ? (
                  <IconMetamask height="1em" width="1em" />
                ) : (
                  <IconWarning height="1em" width="1em" />
                )}
                <div className="ml-4 leading-none text-white">{label}</div>
              </div>
            </Button>
            {/* {!networkIsOk && authOk && (
              <div className="absolute top-14 right-0 bg-black rounded-lg p-8 w-80">
                You are not on the Ethereum Mainnet at the moment
              </div>
            )} */}
          </div>
          {!menuIsOpen ? (
            <IconMenu className="m:hidden cursor-pointer" onClick={openMenu} />
          ) : (
            <IconMenuClose
              className="m:hidden cursor-pointer"
              onClick={closeMenu}
            />
          )}
        </div>
      </header>
      <div
        className={`
        pt-[78px] m:hidden z-40 text-lg bg-darkOrange w-full flex flex-col text-center absolute top-0 transition-transform 
        ${menuIsOpen ? 'translate-y-[0%]' : 'translate-y-[-100%]'}
        `}
      >
        <div className="p-4 w-full border-b border-accent border-opacity-10">
          <div className="w-[200px] m-auto relative">
            <Button onClick={handleMetamaskClick}>
              <div className="flex items-center w-64 justify-center">
                {networkIsOk ? (
                  <IconMetamask height="1em" width="1em" />
                ) : (
                  <IconWarning height="1em" width="1em" />
                )}
                <div className="ml-4 leading-none text-white">{label}</div>
              </div>
            </Button>
            {!networkIsOk && (
              <Button className="mt-4" onClick={handleSwitchNetwork}>
                <div className="flex items-center w-64 justify-center">
                  <div className="leading-none text-white">
                    {t('switch_to_arbitrum').toUpperCase()}
                  </div>
                </div>
              </Button>
            )}
          </div>
        </div>
        {URLS_MAP.map((item) => (
          <div
            key={`nav-mobile-${item.url}`}
            className="p-5 border-b border-accent border-opacity-10"
          >
            <NavLink to={item.url} text={item.text} onClick={closeMenu} />
          </div>
        ))}
      </div>
    </div>
  )
}
