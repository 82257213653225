import Decimal from 'decimal.js'
import noop from 'lodash/noop'
import { toDecimal, formatNumber } from 'utils/numbers'

import MaxButton from './MaxButton'
import TextInput from './TextInput'

interface CurrencyInputPanelProps {
  symbol: string
  value: string
  label?: string
  onUserInput?(value: string): void
  available?: Decimal
  availableLabel?: string
  onMax?(): void
  showMaxButton?: boolean
  currency?: string | null
  disabled?: boolean
  dark?: boolean
}

export default function CurrencyInputPanel({
  symbol,
  label = 'You send',
  value,
  availableLabel,
  available,
  onUserInput = noop,
  onMax = noop,
  disabled = false,
  dark = false,
}: CurrencyInputPanelProps) {
  const wrapperStyles = dark
    ? 'bg-accent  bg-opacity-8'
    : 'border border-borderGray focus-within:border-borderGrayFocus'

  const inputStyles = dark
    ? 'text-accent text-shadow-participate'
    : 'text-primary'

  return (
    <div
      className={`text-gray flex items-center px-5 py-3  ${wrapperStyles} rounded-xl`}
    >
      <div className="relative flex justify-between flex-grow flex-column">
        <div className="flex flex-col flex-grow">
          <p
            className={`select-none ${inputStyles} opacity-50 leading-4 mb-1 text-sm tracking-normal`}
          >
            {label}
          </p>
          <TextInput
            value={value}
            placeholder="0.00"
            disabled={disabled}
            className={`flex-grow relative bg-transparent text-xl leading-tight ${inputStyles} focus:outline-none`}
            onChange={onUserInput}
          />
        </div>

        <div className="flex flex-col items-center justify-end">
          <div className="flex items-end self-end">
            {available?.gt(0) &&
              !toDecimal(value).eq(toDecimal(available)) &&
              !disabled && <MaxButton onClick={onMax} />}

            <div className="ml-3 text-xl leading-none">{symbol}</div>
          </div>
        </div>
        {availableLabel && available ? (
          <p className="absolute right-0 text-sm mb-1 leading-4">
            {availableLabel}: {formatNumber(available, { fractionDigits: 6 })}
          </p>
        ) : null}
      </div>
    </div>
  )
}
