import noop from 'lodash/noop'
import { InfoPopover } from 'components'

interface Props<T extends { name: string; popoverText?: string }> {
  tabs?: T[]
  current?: T
  accentBorder?: string
  onTabClick?(tab: T): void
}

export default function Tabs<T extends { name: string; popoverText?: string }>(
  props: Props<T>
) {
  const { tabs = [], onTabClick = noop, current = null, accentBorder } = props

  const isCurrent = (t: T) => t.name === current?.name
  const isLastTab = (index: number) => index === tabs.length - 1

  return (
    <>
      {tabs.map((t, index) => (
        <div
          key={t.name}
          className={`${isLastTab(index) ? '' : 'mr-8'}`}
          onClick={() => onTabClick(t)}
        >
          <div
            className={`box-border pb-1 cursor-pointer text-primary ${
              isCurrent(t)
                ? ` border-b ${
                    accentBorder ? accentBorder : 'border-accent'
                  } border-opacity-50`
                : 'opacity-50'
            } `}
          >
            <div className="text-lg leading-none whitespace-nowrap">
              {t.popoverText ? (
                <InfoPopover
                  text={t.popoverText}
                  positions={['top', 'bottom']}
                  className="max-w-52"
                >
                  <div>{t.name}</div>
                </InfoPopover>
              ) : (
                t.name
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
