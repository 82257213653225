import { useStore } from 'effector-react/effector-react.cjs'
import { openConnectMetamaskModal } from 'models/flow'
import { $authOk, $networkIsOk, switchNetworkFx } from 'models/user'
import React from 'react'
import { t } from 'utils'

interface Props {
  children?: React.ReactNode
  disabled?: boolean
  onClick?: () => void
  className?: string
  secondary?: boolean
  shadow?: boolean
  useMetamask?: boolean
}

export default function Button({
  children,
  disabled = false,
  onClick = () => {},
  className = '',
  secondary = false,
  shadow = false,
  useMetamask = false,
}: Props) {
  const authOk = useStore($authOk)
  const networkIsOk = useStore($networkIsOk)

  const cn = {
    shadow: shadow && !disabled ? 'shadow-button' : '',
    base: 'outline-none px-4 py-2.5 rounded-[0.6rem] focus:outline-none whitespace-nowrap disabled:pointer-events-none',
    bg: `${
      secondary ? 'bg-accentSecondary' : 'bg-accent'
    } hover:bg-opacity-25 active:bg-opacity-30 bg-opacity-20 disabled:bg-white disabled:bg-opacity-10`,
    border: `border ${
      secondary ? 'border-accentSecondary' : 'border-accent'
    } bg-opacity-10 disabled:border disabled:border-borderGray`,
    textStyle:
      'text-white disabled:text-opacity-60 tracking-wider text-base font-normal disabled:text-opacity-60',
  }
  const handleClick = () => {
    if (!useMetamask) {
      onClick()
    } else {
      if (!authOk) {
        openConnectMetamaskModal()
        return
      }
      if (!networkIsOk) {
        switchNetworkFx()
        return
      }
      onClick()
    }
  }

  return (
    <button
      className={`${cn.base} ${cn.bg} ${cn.border} ${cn.textStyle} ${cn.shadow} ${className}`}
      disabled={
        useMetamask ? (!authOk || !networkIsOk ? false : disabled) : disabled
      }
      type="button"
      onClick={handleClick}
    >
      {useMetamask && authOk && networkIsOk && children}
      {useMetamask && !authOk && t('connect_metamask')}
      {useMetamask && authOk && !networkIsOk && t('switch_to_arbitrum')}
      {!useMetamask && children}
    </button>
  )
}
