import Loader from 'components/Loader'
import { t } from 'utils'

export default function MetamaskWaitingModal() {
  return (
    <div className="flex flex-col items-center flex-grow px-14">
      <p className="my-4 text-[1.625rem] leading-none font-semibold mb-4">
        {t('connecting_metamask')}
      </p>
      <Loader />
    </div>
  )
}
