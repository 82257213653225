export default function Loader() {
  return (
    <div className="mt-8 loader-triangle">
      <svg
        width="42"
        height="32"
        fill="none"
        viewBox="0 -1 42 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          fill="none"
          stroke="#FF8B50"
          strokeWidth="1"
          points="0,0 40,0 20,30"
        />
      </svg>
    </div>
  )
}
