import { guard } from 'effector'
import jwtDecode from 'jwt-decode'
import get from 'lodash/get'
import isString from 'lodash/isString'
import {
  emptyStakingInfo,
  fetchGeneralInfoFx,
  setPools,
  setStakingInfo,
} from 'models/app'
import {
  $networkIsOk,
  $user,
  clock,
  loadUserFx,
  logoutUser,
  resetUserSession,
  setNetworkIsOk,
  setUserAddress,
  setUserSession,
} from 'models/user'
import { chainIds } from 'utils/consts'
import dayjs from 'utils/dayjs'

logoutUser.watch(() => {
  localStorage.clear()
})

$networkIsOk.on(setNetworkIsOk, (_, ok) => ok)

$user
  .on(loadUserFx, (state) => {
    const address = localStorage.getItem('address') ?? ''
    if (address !== '') {
      const session = localStorage.getItem(address) ?? ''
      return { ...state, session, address }
    }
    return state
  })
  .on(setUserAddress, (state, address) => {
    localStorage.setItem('address', address)
    const session = localStorage.getItem(address) ?? ''
    return { ...state, address, session }
  })
  .on(setUserSession, (state, session) => {
    const address = get(jwtDecode(session ?? ''), 'addr')
    if (isString(address) && address !== '') {
      localStorage.setItem(address, session)
      return { ...state, session, address }
    }
    return { ...state, session: '', address: '' }
  })
  .on(resetUserSession, (state) => {
    localStorage.setItem(state.address, '')
    return { ...state, session: '' }
  })

guard({
  source: $user,
  clock,
  filter: ({ session }) => {
    if (session === '') return false
    const exp = +(get(jwtDecode(session ?? ''), 'exp') ?? 0)
    return exp < dayjs().unix()
  },
  target: logoutUser,
})

clock.watch(() => {
  if (window.ethereum && window.ethereum.chainId) {
    setNetworkIsOk(
      window.ethereum.chainId === chainIds.Arbitrum.ArbitrumMainnet
    )
  }
})

// Run clock for JWT expiration checks
setInterval(clock, 1000)

// Refetch data if session or address has changed
$user.watch(() => {
  fetchGeneralInfoFx().catch(() => {
    setPools([])
    setStakingInfo(emptyStakingInfo)
  })
})
