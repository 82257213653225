import Button from 'components/Button'
import { ReactComponent as CheckIcon } from 'icons/circle-check.svg'
import { closeModal } from 'models/flow'
import { t } from 'utils'

export default function RegistrationSuccessModal() {
  const handleClick = () => {
    closeModal()
  }
  return (
    <div className="flex flex-col items-center flex-grow px-14">
      <p className="my-4 text-[1.625rem] leading-none font-semibold mb-4">
        {t('registration_success')}
      </p>
      <div className="mt-6 mb-12">
        <CheckIcon width="2.5em" height="2.5em" />
      </div>
      <Button className="w-full uppercase" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
