import get from 'lodash/get'
import strings from './strings.json'
import { EthereumTx } from 'gqlgen/public'

type ValuesObject = {
  [k: string]: any
}

const t = (k: string, value?: ValuesObject) => {
  const text = get(strings, k, k)

  if (!value) {
    return text
  }

  const regex = /(\{\{.*?\}\})|(<.*?\/>)/

  return text.split(regex).map((el: string, index: number) => {
    const isKey = (s: string) => /\{+[^}]+\}/.test(s)
    const isBr = (s: string) => /br\s*/.test(s)

    if (isKey(el)) {
      const pure = el.replace(/\{|\}/g, '')
      return get(value, pure, el)
    }

    if (isBr(el)) {
      return <br key={'br-' + index} />
    }

    return el
  })
}

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

function truncateAddress(address: string, length: number): string {
  if (!address) {
    return ''
  }

  return `${address.substr(0, length)}...${address.substr(-length)}`
}

function convertTxToSendTxRequest(tx: EthereumTx) {
  const params: any = {
    from: tx.from,
    nonce: tx.nonce,
    gas: tx.gas,
    to: tx.to,
    value: tx.value,
    data: tx.data,
    chainId: tx.chainId,
  }

  if (tx.gasFeeCap) params.gasFeeCap = tx.gasFeeCap
  if (tx.gasTipCap) params.gasTipCap = tx.gasTipCap
  if (tx.gasPrice) params.gasPrice = tx.gasPrice

  return {
    method: 'eth_sendTransaction',
    params: [params],
  }
}

export { t, escapeRegExp, truncateAddress, convertTxToSendTxRequest }
