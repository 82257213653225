import { t } from 'utils'
import { ReactComponent as CrossIcon } from 'icons/cross-error.svg'
import Button from 'components/Button'
import { closeModal } from 'models/flow'

export default function MetamaskFailureModal() {
  const handleClick = () => {
    closeModal()
  }
  return (
    <div className="flex flex-col items-center flex-grow px-14">
      <p className="my-4 text-[1.625rem] leading-none font-semibold mb-4">
        {t('metamask_failed')}
      </p>
      <div className="my-7">
        <CrossIcon width="3em" height="3em" />
      </div>
      <Button className="w-full uppercase" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
