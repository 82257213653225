import { getSdk, Blockchain, BlockchainNetwork } from 'gqlgen/public'
import { GraphQLClient } from 'graphql-request'

const endpoint =
  process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT ??
  'http://localhost:4124/graphql'

// const authorization =
//   process.env.REACT_APP_GRAPHQL_PUBLIC_AUTHORIZATION ??
//   'Basic cmF6ZXI6YWZiOVZjUVhxN0ViN1dMS2d5UUJaQ28='

const clientPublic = new GraphQLClient(endpoint, {
  // headers: {
  //   Authorization: authorization,
  // },
})

export const graphqlSdk = getSdk(clientPublic)

export const chainIds = {
  [Blockchain.Ethereum]: {
    [BlockchainNetwork.EthereumMainnet]: '0x1',
    [BlockchainNetwork.EthereumRinkebyTestnet]: '0x4',
  },
  [Blockchain.Arbitrum]: {
    [BlockchainNetwork.ArbitrumMainnet]: '0xa4b1',
    [BlockchainNetwork.ArbitrumTestnet]: '0x66eeb',
  },
}

export const poolAddressesLinks = {
  [BlockchainNetwork.ArbitrumMainnet]: 'https://arbiscan.io',
  [BlockchainNetwork.ArbitrumTestnet]: 'https://testnet.arbiscan.io',
  [BlockchainNetwork.EthereumMainnet]: 'https://etherscan.io',
  [BlockchainNetwork.EthereumRinkebyTestnet]: 'https://rinkeby.etherscan.io',
  [BlockchainNetwork.BscMainnet]: 'https://bscscan.com',
  [BlockchainNetwork.BscTestnet]: 'https://testnet.bscscan.com',
}

export const chainInfo = {
  '0xa4b1': {
    chainId: '0xa4b1',
    chainName: 'Arbitrum One',
    nativeCurrency: {
      name: 'Aave Ethereum Price (aETH)',
      symbol: 'AETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://arbiscan.io'],
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
  },
  '0x66eeb': {
    chainId: '0x66eeb',
    chainName: 'Arbitrum One Testnet',
    nativeCurrency: {
      name: 'Aave Ethereum Price (aETH)',
      symbol: 'AETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://rinkeby-explorer.arbitrum.io/#/'],
    rpcUrls: ['https://rinkeby.arbitrum.io/rpc'],
  },
}
